import {
  InferType,
  string,
} from 'yup';

import ServiceProvidersEnum from '@/enums/ServiceProvider';

const ServiceProvider = string().required().oneOf(Object.values(ServiceProvidersEnum), 'Unsupported Provider');

// eslint-disable-next-line @typescript-eslint/no-redeclare
type ServiceProvider = InferType<typeof ServiceProvider> & ServiceProvidersEnum

export default ServiceProvider;

export default async (store) => {
  window.addEventListener('storage', ({
    key, newValue,
  }) => {
    if ([
      'session/set',
      'session/delete',
    ].includes(key) && newValue !== null) {
      store.commit(key, JSON.parse(newValue));
    }
  });

  store.subscribe((mutation, state) => {
    if (mutation.type === 'session/login') {
      window.localStorage.setItem('session/set', JSON.stringify(state.session));
      window.localStorage.removeItem('session/set');
    }

    if (mutation.type === 'session/logout') {
      window.localStorage.setItem('session/delete', true);
      window.localStorage.removeItem('session/delete');
    }
  });
};

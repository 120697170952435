/* eslint-disable no-param-reassign */
import {
  compact,
  // find,
  groupBy, keyBy, omit, uniq,
} from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionTree, GetterTree, MutationTree,
} from 'vuex';

import {
  GetSellerProducts,
} from '@/api/clients/sellerDataApi.types';
import BrandConfiguration, {
  CreateBrandConfigurationPayload,
} from '@/interfaces/BrandConfiguration';
import ApiClient, {
  GetSellersResponse,
} from '@/plugins/ApiClient';

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $apiClient: ApiClient,
  }
}

const {
  ADD_SELLER,
  CLEAR_SELLERS,
  SELLERS_FETCH_SUCCESS,
  UPDATE_BRAND_CONFIGURATIONS_SUCCESS,
  DELETE_BRAND_CONFIGURATIONS_SUCCESS,
  CREATE_BRAND_CONFIGURATIONS_SUCCESS,
  UPDATE_BRAND_CONFIGURATION_ID_TO_EDIT,
  GET_SELLER_PRODUCTS_SUCCESS,
  GET_BRAND_CONFIGURATIONS_SUCCESS,
} = {
  ADD_SELLER: 'ADD_SELLER',
  CLEAR_SELLERS: 'CLEAR_SELLERS',
  SELLERS_FETCH_SUCCESS: 'SELLERS_FETCH_SUCCESS',
  UPDATE_BRAND_CONFIGURATION_ID_TO_EDIT: 'UPDATE_BRAND_CONFIGURATION_ID_TO_EDIT',
  UPDATE_BRAND_CONFIGURATIONS_SUCCESS: 'UPDATE_BRAND_CONFIGURATIONS_SUCCESS',
  DELETE_BRAND_CONFIGURATIONS_SUCCESS: 'DELETE_BRAND_CONFIGURATIONS_SUCCESS',
  CREATE_BRAND_CONFIGURATIONS_SUCCESS: 'CREATE_BRAND_CONFIGURATIONS_SUCCESS',
  GET_SELLER_PRODUCTS_SUCCESS: 'GET_SELLER_PRODUCTS_SUCCESS',
  GET_BRAND_CONFIGURATIONS_SUCCESS: 'GET_BRAND_CONFIGURATIONS_SUCCESS',
};

export type RootState = {
  sellers: {
    slug: string
    name: string | null
  }[],
  areBrandConfigurationsLoaded: boolean
  brandConfigurationsById: Record<number, BrandConfiguration>,
  brandConfigurationIdToEdit: number | null,
  brandConfigurations: BrandConfiguration[],
  sellerAsins: Record<string, string[]>
  asins: Record<string, GetSellerProducts.Product[]>
  brands: string[]
}

export const state = ():RootState => ({
  sellers: [
  ],
  sellerAsins: {
  },
  asins: {

  },
  brandConfigurationIdToEdit: null,
  brandConfigurationsById: {
  },
  brandConfigurations: [
  ],
  brands: [
  ],
  areBrandConfigurationsLoaded: true,
});

export const getters: GetterTree<RootState, RootState> = {
  sellers: ({
    sellers,
  }) => sellers,
  alreadyConfiguredCatalogBrandKeys: ({
    brandConfigurations,
  }) => brandConfigurations.flatMap(({
    brandKeys,
  }) => brandKeys),
  targetsByAsin: ({
    asins,
  }) => (asin:string, marketplaceId:string) => {
    const productsForAsin = asins[asin] || [
    ];

    return productsForAsin
      .filter((product) => product.marketplaceId === marketplaceId)
      .map((product) => ({
        sku: product.sku,
        accountId: product.spapi,
      }));
  },

  areBrandConfigurationsLoaded({
    areBrandConfigurationsLoaded,
  }) {
    return areBrandConfigurationsLoaded;
  },
  brandConfigurationIdToEdit({
    brandConfigurationIdToEdit,
  }) {
    return brandConfigurationIdToEdit;
  },
  brandConfigurations({
    brandConfigurations,
  }) {
    return brandConfigurations;
  },
  brandConfigurationsById({
    brandConfigurationsById,
  }) {
    return brandConfigurationsById;
  },
  asinMetadata: ({
    asins,
  }) => (asin:string) => {
    const productsForAsin = asins[asin] || [
    ];
    return productsForAsin[0];
  },
  sellerBySlug:
    ({
      sellers,
    }) => (sellerSlug:string) => sellers.find((seller) => seller.slug === sellerSlug),
};

export const mutations: MutationTree<RootState> = {
  [ADD_SELLER]: (stt, {
    seller,
  }) => {
    stt.sellers.push(seller);
  },
  [SELLERS_FETCH_SUCCESS]: (stt, {
    sellers,
  }: GetSellersResponse) => {
    stt.sellers = sellers;
  },
  [CLEAR_SELLERS]: (stt) => {
    stt.sellers = [
    ];
  },
  [GET_SELLER_PRODUCTS_SUCCESS]: (stt, {
    sellerId,
    products,
  }:{sellerId: number, products: GetSellerProducts.Response}) => {
    const brands = compact(uniq(products.map((product) => product.brand)));
    stt.brands = brands;
    stt.sellerAsins[sellerId] = products.map((product) => product.asin);
    stt.asins = groupBy(products, 'asin');
  },

  [GET_BRAND_CONFIGURATIONS_SUCCESS]: (stt, {
    brandConfigurations,
  }:{
    sellerId: number,
    brandConfigurations: BrandConfiguration[]
  }) => {
    stt.areBrandConfigurationsLoaded = true;
    stt.brandConfigurations = brandConfigurations;
    stt.brandConfigurationsById = keyBy(brandConfigurations, 'id');
  },
  [CREATE_BRAND_CONFIGURATIONS_SUCCESS]: (stt, {
    brandConfiguration,
  }:{
    sellerId: number,
    brandConfiguration: BrandConfiguration
  }) => {
    stt.brandConfigurationsById[brandConfiguration.id] = brandConfiguration;
  },
  [DELETE_BRAND_CONFIGURATIONS_SUCCESS]: (stt, {
    brandConfigurationId,
  }:{
    sellerId: number,
    brandConfigurationId: number
  }) => {
    stt.brandConfigurationsById = omit(stt.brandConfigurationsById, brandConfigurationId);
  },
  [UPDATE_BRAND_CONFIGURATIONS_SUCCESS]: (stt, {
    brandConfiguration,
  }:{
    sellerId: number,
    brandConfiguration: BrandConfiguration
  }) => {
    stt.brandConfigurationsById[brandConfiguration.id] = brandConfiguration;
  },
  [UPDATE_BRAND_CONFIGURATION_ID_TO_EDIT]: (stt, {
    id,
  }:{
    id: number | null
  }) => {
    stt.brandConfigurationIdToEdit = id;
  },
};

export const actions:ActionTree<RootState, RootState> = {
  async get({
    commit,
  }) {
    const response = await this.$apiClient.getSellersForCurrentUser();
    commit(SELLERS_FETCH_SUCCESS, response);
  },
  async getProductsForSeller({
    commit,
  }, sellerId:number) {
    const response = await this.$apiClient.getSellerProducts(sellerId);
    commit(GET_SELLER_PRODUCTS_SUCCESS, {
      products: response,
      sellerId,
    });
  },
  updateBrandConfigurationIdToEdit({
    commit,
  }, id: number | null) {
    commit(UPDATE_BRAND_CONFIGURATION_ID_TO_EDIT, {
      id: id || null,
    });
  },
  async getBrandConfigurationsForSeller({
    commit,
  }, sellerId:number) {
    const brandConfigurations = await this.$apiClient.getBrandConfigurationsForSeller(sellerId);
    commit(GET_BRAND_CONFIGURATIONS_SUCCESS, {
      brandConfigurations,
    });
  },
  async createBrandConfigurationForSeller({
    commit,
  }, {
    sellerId,
    payload,
  }:{sellerId:number, payload: CreateBrandConfigurationPayload}) {
    const brandConfiguration = await this.$apiClient.createBrandConfigurationForSeller(
      sellerId,
      payload,
    );
    commit(CREATE_BRAND_CONFIGURATIONS_SUCCESS, {
      brandConfiguration,
    });
  },

  async updateBrandConfigurationForSeller({
    commit,
  }, {
    sellerId,
    brandConfigurationId,
    payload,
  }:{
    sellerId:number,
    brandConfigurationId:number,
    payload: CreateBrandConfigurationPayload
  }) {
    const brandConfiguration = await this.$apiClient.updateBrandConfiguration(
      sellerId,
      brandConfigurationId,
      payload,
    );
    commit(UPDATE_BRAND_CONFIGURATIONS_SUCCESS, {
      sellerId,
      brandConfigurationId,
      brandConfiguration,
    });
  },
  async deleteBrandConfigurationForSeller({
    commit,
  }, {
    sellerId,
    brandConfigurationId,
  }:{
    sellerId:number,
    brandConfigurationId:number,
  }) {
    await this.$apiClient.deleteBrandConfiguration(
      sellerId,
      brandConfigurationId,
    );
    commit(DELETE_BRAND_CONFIGURATIONS_SUCCESS, {
      sellerId,
      brandConfigurationId,
    });
  },
  async add({
    commit,
  }, payload) {
    commit(ADD_SELLER, payload);
  },
  async clear({
    commit,
  }) {
    commit(CLEAR_SELLERS);
  },
};

import Kpi from '@/enums/Kpi';
import formatValueForDisplay from '@/helpers/formatValueForDisplay';

export default {
  [Kpi.productComplexity]: {
    inputType: 'decimal',
    displayText: 'Product Complexity',
    tooltipText: 'Based on the number of products that make up 60% of EBITDA (Trailing 12 Months)',
    recommendation: 'Focus on Hero ASINs as you currently don\'t have clear profit drivers in your portfolio - this will help reduce operational complexity',
    maximumFractionDigits: 2,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.ebitdaYearly]: {
    inputType: 'decimal',
    displayText: 'EBITDA Growth YoY',
    tooltipText: 'Based on the brand EBITDA growth rate of the last year (Trailing 12 Months) vs the year before',
    recommendation: 'The YoY development has been challenging - rethink your product portfolio, ways of creating lasting customer loyalty, and consider expanding to other regions where the upside for your product may be higher',
    maximumFractionDigits: 0,
    addPercentageChar: true,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.ebitdaMonthly]: {
    inputType: 'decimal',
    displayText: 'EBITDA Growth MoM',
    tooltipText: 'Based on the brand EBITDA growth rate of the last completed month, compared to the same month in the previous year.',
    recommendation: 'Compared to last year’s period, current profitability is challenged: rethink your product portfolio, analyze your cost mix, think through ways of creating lasting customer loyalty, and consider expanding to other regions where the upside for your product may be higher',
    maximumFractionDigits: 0,
    addPercentageChar: true,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.bestSellerRank]: {
    inputType: 'decimal',
    displayText: 'Best Seller Rank',
    tooltipText: 'Based on the current Best Seller Ranking across subcategories',
    recommendation: 'Increasing your conversion and optimising your PPC further can help you improve your BSR along with measure such as promotions',
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.profitMargin]: {
    inputType: 'decimal',
    displayText: 'Profit Margin',
    tooltipText: 'Based on the profit margin across the brand (Trailing 12 Months)',
    recommendation: 'Focus on understanding what is hurting your profit margin - check the impact of metrics such as a TACOS, Return Rates and COGS which are weighting proportionally high',
    maximumFractionDigits: 0,
    addPercentageChar: true,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.returnRate]: {
    inputType: 'percentage',
    displayText: 'Return Rate',
    tooltipText: 'Based on the % of products returned in the last year',
    recommendation: 'Address product quality issues and/or improve the description if your product could be a bit misleading. The high return rate is both costly and further impacting your BSR and ratings',
    maximumFractionDigits: 0,
    addPercentageChar: true,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.repeatCustomerRate]: {
    inputType: 'percentage',
    displayText: 'Repeat Customer Rate',
    tooltipText: 'Based on the % of purchases that came from repeat buyers in the last 18 months',
    recommendation: 'Explore ways to increase your customer loyalty - for instance by investing in your branding, creating a flyer/marketing materials, or improved packaging. Those factors can help you create strong customer LTV',
    maximumFractionDigits: 0,
    addPercentageChar: true,
    formatFunction: formatValueForDisplay,
  },
  // categoryMarketshare: {
  // displayText: 'Category Marketshare',
  // tooltipText: 'Based on a comparison with the bestseller ASINs from the relevant subcategories',
  // },
  [Kpi.policyViolations]: {
    inputType: 'decimal',
    displayText: 'Policy Violations',
    tooltipText: 'Based on the number of policy violations across the Seller Central Account',
    maximumFractionDigits: 2,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.reviewVolume]: {
    inputType: 'decimal',
    displayText: 'Review Volume',
    tooltipText: 'Based on the number of reviews per product (all time)',
    recommendation: 'Getting more (good) reviews is key: Add a flyer to the packaging and ask for reviews or use the Amazon service to ask for reviews',
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.reviewQuality]: {
    inputType: 'percentage',
    displayText: 'Review Quality',
    tooltipText: 'Based on the negative review rate (all time)',
    addPercentageChar: true,
    recommendation: 'Focus on improving your product and your listing. You seem to be setting wrong expectations that create a poor customer experience',
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.reviewQuota]: {
    inputType: 'percentage',
    displayText: 'Review Quota',
    tooltipText: 'Based on the number of reviews per product in the last 30 days',
    addPercentageChar: true,
    recommendation: 'Getting more (good) reviews is key: Add a flyer to the packaging and ask for reviews or use the Amazon service to ask for reviews',
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.rating]: {
    inputType: 'decimal',
    displayText: 'Rating',
    tooltipText: 'Based on the rating across products (all time)',
    recommendation: 'Invest in the customer experience and improve your packaging and listing. If necessary, improve the quality of your product',
    maximumFractionDigits: 2,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.orderDefectRate]: {
    inputType: 'percentage',
    displayText: 'Order Defect Rate',
    tooltipText: 'Based on the order defect rate across the Seller Central Account',
    addPercentageChar: true,
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.tacos]: {
    inputType: 'percentage',
    displayText: 'TACoS',
    tooltipText: 'Based on the Total Average Cost of Advertising (Ad Cost / Amazon Sales) in the last completed month.',
    addPercentageChar: true,
    recommendation: 'Take the time to analyze your campaigns and optimize campaigns that are too expensive',
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
  [Kpi.shopifyRevenueYoY]: {
    inputType: 'decimal',
    displayText: 'Shopify Revenue YoY',
    tooltipText: 'Based on the Shopify Revenue (Total Sales) growth of the last year (Trailing 12 Months) vs the year before',
    recommendation: '',
    addPercentageChar: true,
    maximumFractionDigits: 0,
    formatFunction: formatValueForDisplay,
  },
};

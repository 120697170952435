import {
  InferType,
  string,
} from 'yup';

const Email = string().email();

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Email = InferType<typeof Email>

export default Email;

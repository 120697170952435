import * as FullStory from '@fullstory/browser';

export default (store) => {
  store.watch(
    (_state, getters) => ({
      user: getters['session/user'],
      isLoggedIn: getters['session/isLoggedIn'],
      isAdminSession: getters['session/isAdminSession'],
    }),
    ({
      user, isLoggedIn, isAdminSession,
    }) => {
      if (isAdminSession) {
        FullStory.shutdown();
      } else if (isLoggedIn && user && user.id) {
        const displayName = user.email;
        FullStory.identify(user.id, {
          displayName,
        });
      }
    },
    {
      immediate: true,
    },
  );
};

import * as Sentry from '@sentry/browser';

import {
  User,
} from '@/models';

const createSentryContextForSession = ({
  user,
}:{
  user: User,
}) => {
  Sentry.setUser({
    id: `${user.id}`,
  });
};

export default createSentryContextForSession;

export enum ServiceProvider {
  SPAPI_BASIC = 'xapix_spapi_basic',
  SPAPI_OPS = 'xapix_spapi_ops',
  AMAZON_ADS = 'xapix_amazon_ads',
  SHOPIFY = 'xapix_shopify',
  SHOPIFY_CUSTOM = 'custom_shopify'
}

export const ServiceProviderNames = {
  [ServiceProvider.AMAZON_ADS]: 'Amazon Advertising',
  [ServiceProvider.SPAPI_BASIC]: 'Amazon Seller Central',
  [ServiceProvider.SPAPI_OPS]: 'Amazon Seller Central',
  [ServiceProvider.SHOPIFY]: 'Shopify (Beta)',
  [ServiceProvider.SHOPIFY_CUSTOM]: 'Shopify (Beta)',
};

export default ServiceProvider;


export default {
  name: 'TextField',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
};

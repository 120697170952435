// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';

import {
  ERROR,
} from '@/constants';

const possibleErrors:{ [index:string]: string } = {
  'account-already-active': 'The authenticated account is already active for another seller and thus will stay deactivated. Please contact support.',
  'invalid-code': 'OAuth code to token call failed, Please try again',
  'invalid-callback-params': 'Received invalid callback params, Plesase try again',
};

const serviceProviderAuthErrorsMiddleware: Middleware = async ({
  store,
  route,
}) => {
  const error = route.query.error as string || '';
  if (error !== '' && error !== 'account-already-active') {
    await store.dispatch('alerts/setNotification', {
      type: ERROR,
      html: true,
      message: possibleErrors[error] || error,
    });
  }
};

export default serviceProviderAuthErrorsMiddleware;

const KPI_SCORE_RULES = {
  productComplexity: {
    0: {
      gte: 10,
    },
    25: {
      lte: 9,
      gte: 7,
    },
    50: {
      lte: 6,
      gte: 5,
    },
    75: {
      lte: 4,
      gte: 3,
    },
    100: {
      lte: 2,
      gte: 1,
    },
  },
  profitMargin: {
    0: {
      lt: 9.99,
    },
    25: {
      gte: 10,
      lte: 14.99,
    },
    50: {
      gte: 15,
      lte: 19.99,
    },
    75: {
      gte: 20,
      lte: 24.99,
    },
    100: {
      gte: 25,
    },
  },
  returnRate: {
    0: {
      gte: 0.05,
    },
    25: {
      lte: 0.049,
      gte: 0.04,
    },
    50: {
      lte: 0.039,
      gte: 0.03,
    },
    75: {
      lte: 0.029,
      gte: 0.02,
    },
    100: {
      lte: 0.019,
      gte: 0,
    },
  },
  repeatCustomerRate: {
    0: {
      gte: 0,
      lte: 0.049,
    },
    25: {
      gte: 0.05,
      lte: 0.099,
    },
    50: {
      gte: 0.1,
      lte: 0.1499,
    },
    75: {
      gte: 0.15,
      lte: 0.1999,
    },
    100: {
      gte: 0.2,
    },
  },
  ebitdaYearly: {
    0: {
      lte: -20,
    },
    25: {
      gte: -19.99,
      lte: 0,
    },
    50: {
      gte: 0.1,
      lte: 9.99,
    },
    75: {
      gte: 10,
      lte: 29.99,
    },
    100: {
      gte: 30,
    },
  },
  ebitdaMonthly: {
    0: {
      lte: -20,
    },
    25: {
      gte: -19.99,
      lt: 0,
    },
    50: {
      gt: 0,
      lte: 9.99,
    },
    75: {
      gte: 10,
      lte: 29.99,
    },
    100: {
      gte: 30,
    },
  },
  bestSellerRank: {
    0: {
      gte: 99.5,
    },
    25: {
      lt: 99.5,
      gte: 49.5,
    },
    50: {
      lt: 49.5,
      gte: 24.5,
    },
    75: {
      lt: 24.5,
      gte: 9.5,
    },
    100: {
      lt: 9.5,
      gte: 1,
    },
  },
  reviewVolume: {
    0: {
      gte: 0,
      lte: 999,
    },
    25: {
      gte: 1000,
      lte: 2499,
    },
    50: {
      gte: 2500,
      lte: 4999,
    },
    75: {
      gte: 5000,
      lte: 9999,
    },
    100: {
      gte: 10000,
    },
  },
  reviewQuality: {
    0: {
      gte: 0.20,
    },
    25: {
      lte: 0.19,
      gte: 0.17,
    },
    50: {
      lte: 0.16,
      gte: 0.15,
    },
    75: {
      lte: 0.14,
      gte: 0.1,
    },
    100: {
      lte: 0.09,
      gte: 0,
    },
  },
  reviewQuota: {
    0: {
      lte: 0.49,
    },
    25: {
      gte: 0.50,
      lte: 0.59,
    },
    50: {
      gte: 0.60,
      lte: 0.69,
    },
    75: {
      gte: 0.70,
      lte: 0.79,
    },
    100: {
      gte: 0.80,
    },
  },
  rating: {
    0: {
      gte: 0,
      lte: 2.49,
    },
    25: {
      gte: 2.5,
      lte: 3.49,
    },
    50: {
      gte: 3.50,
      lte: 3.99,
    },
    75: {
      gte: 4,
      lte: 4.49,
    },
    100: {
      gte: 4.5,
    },
  },
  orderDefectRate: {
    0: {
      gte: 0.2,
    },
    25: {
      lte: 0.19,
      gte: 0.10,
    },
    50: {
      lte: 0.099,
      gte: 0.050,
    },
    75: {
      lte: 0.049,
      gte: 0.025,
    },
    100: {
      lte: 0.024,
      gte: 0,
    },
  },
  policyViolations: {
    0: {
      gte: 20,
    },
    25: {
      lte: 19,
      gte: 10,
    },
    50: {
      lte: 9,
      gte: 5,
    },
    75: {
      lte: 4,
      gte: 1,
    },
    100: {
      lte: 0,
    },
  },
  tacos: {
    0: {
      gte: 0.25,
    },
    25: {
      lte: 0.24,
      gte: 0.18,
    },
    50: {
      lte: 0.17,
      gte: 0.14,
    },
    75: {
      lte: 0.13,
      gte: 0.08,
    },
    100: {
      lte: 0.07,
    },
  },
  shopifyRevenueYoY: {
    0: {
      lte: -20,
    },
    25: {
      gt: -20,
      lte: 0,
    },
    50: {
      gt: 0,
      lte: 9.99,
    },
    75: {
      gt: 9.99,
      lte: 29.99,
    },
    100: {
      gt: 29.99,
    },
  },
};

export default KPI_SCORE_RULES;

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';
import jwt from 'jsonwebtoken';

const shopifyInstallAuthMiddleware:Middleware = async ({
  redirect,
  route,
  $axios,
  $config,
  $apiClient,
}) => {
  const sellerSyncToken = route.query.success as string;

  try {
    await $axios.get(
      `${$config.sellerSyncAuthUrl}/public/session/verify?jwt=${sellerSyncToken}`,
      {
        withCredentials: true,
      },
    );

    const {
      sel: externalId,
    } = jwt.decode(sellerSyncToken) as { sel: string, acc: string, agg: string};
    try {
      await $apiClient.authShopify(externalId);
      redirect({
        name: 'shopify-sign-up',
        query: {
          token: sellerSyncToken,
        },
      });
    } catch (err:any) {
      redirect({
        name: 'shopify-login',
        query: {
          token: sellerSyncToken,
        },
      });
    }
  } catch (err:any) {
    redirect(`authorizations/error?error=${err.message}`);
  }
};

export default shopifyInstallAuthMiddleware;

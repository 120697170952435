// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';

const redirectMiddleware: Middleware = async ({
  route,
  redirect,
}) => {
  if (route.name === 'invites-aggregatorSlugifiedName') {
    redirect({
      name: 'sign-up',
      query: route.query,
    });
  }
};

export default redirectMiddleware;


import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'UserMenu',
  computed: {
    fullName() {
      return this.$store.getters['session/fullName'];
    },
    organizationName() {
      return this.$store.getters['session/organization'].name || '';
    },
    subscriptionPlan() {
      return this.$store.getters['session/organization'].plan || 'No plan';
    },
    isOperationsUser() {
      return this.$store.getters['session/isOperationsUser'];
    },
    isSubscriptionDialogOpen() {
      return this.$store.getters['main/isSubscriptionDialogOpen'];
    },
  },
  methods: {
    openSubscriptionDialog() {
      this.$store.dispatch('main/openSubscriptionDialog');
    },
    closeSubscriptionDialog() {
      this.$store.dispatch('main/closeSubscriptionDialog');
    },
    signOut() {
      this.$store.dispatch('session/logout').then(() => {
        this.$router.push({
          name: 'login',
        });
      });
    },
  },
});

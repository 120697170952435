

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SellerNavbar',
  computed: {
    isAdminSession() {
      const isAdminSession = this.$store.getters['session/isAdminSession'];
      return isAdminSession;
    },
  },
});

import {
  InferType,
  string,
} from 'yup';

const Slug = string().min(3).max(64)
  .matches(/^[a-z0-9_-]*$/, 'Must be a alphanumeric with specials characters _-');

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Slug = InferType<typeof Slug>

export default Slug;

/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionTree, GetterTree, MutationTree,
} from 'vuex';

import {
  AMAZON_MARKETPLACES_BY_ID,
} from '@/constants/amazonMarketplaces';
import ListingAttributes from '@/enums/ListingAttributes';

const {
  OPEN_LISTING_PANEL,
  CLOSE_LISTING_PANEL,
  UPDATE_LISTING_PANEL,
} = {
  OPEN_LISTING_PANEL: 'OPEN_LISTING_PANEL',
  CLOSE_LISTING_PANEL: 'CLOSE_LISTING_PANEL',
  UPDATE_LISTING_PANEL: 'UPDATE_LISTING_PANEL',
};

export type RootState = {
  selectedListingPanel: {
    asin: string | null,
    marketplaceId: string | null
    listingOptimizationEnabled: boolean
    listingAttributesToOptimize: ListingAttributes[]
    listingOptimization: {
      [ListingAttributes.TITLE]?: {
        live: string
        recommended: string
      }
      [ListingAttributes.BULLET_POINTS]?: {
        live: string[]
        recommended: string[]
      }
      [ListingAttributes.DESCRIPTION]?: {
        live: string
        recommended: string
      }
      [ListingAttributes.SEARCH_TERMS]?: {
        live: string
        recommended: string
      }
    }
    keywordsAdded: {
        keyword: string
        rating: number
        status: 'added' | 'kept' | 'removed' | 'unused'
      }[]
  }
  isOpportunity: boolean
  isListingPanelOpen: boolean
  forceOptimization: boolean
  performanceFocus: boolean
}

export const state = ():RootState => ({
  selectedListingPanel: {
    asin: null,
    marketplaceId: null,
    listingOptimizationEnabled: false,
    listingOptimization: {
    },
    keywordsAdded: [
    ],
    listingAttributesToOptimize: [
    ],
  },
  isOpportunity: false,
  isListingPanelOpen: false,
  forceOptimization: false,
  performanceFocus: false,
});

export const getters: GetterTree<RootState, RootState> = {
  isListingPanelOpen: ({
    isListingPanelOpen,
  }) => isListingPanelOpen,
  selectedListingPanel: ({
    selectedListingPanel,
  }) => {
    const marketplaceMetadata = selectedListingPanel.marketplaceId
      ? AMAZON_MARKETPLACES_BY_ID[selectedListingPanel.marketplaceId]
      : null;
    return {
      ...selectedListingPanel,
      marketplaceName: marketplaceMetadata?.marketplaceName,
      listingOptimizationEnabled: marketplaceMetadata?.listingOptimizationEnabled || false,
    };
  },
  forceOptimization: ({
    forceOptimization,
  }) => forceOptimization,
  isOpportunity: ({
    isOpportunity,
  }) => isOpportunity,
  performanceFocus: ({
    performanceFocus,
  }) => performanceFocus,
};

export const mutations: MutationTree<RootState> = {
  [CLOSE_LISTING_PANEL]: (stt) => {
    stt.isListingPanelOpen = false;
  },
  [OPEN_LISTING_PANEL]: (stt, payload) => {
    stt.isListingPanelOpen = true;
    stt.isOpportunity = payload.isOpportunity;
    stt.forceOptimization = payload.forceOptimization;
    stt.performanceFocus = payload.performanceFocus;
  },
  [UPDATE_LISTING_PANEL]: (stt, payload: {
    asin: string | null,
    marketplaceId: string | null
    listingOptimizationEnabled: boolean
    listingAttributesToOptimize: ListingAttributes[
    ]
    listingOptimization: {
    [ListingAttributes.TITLE]?: {
      live: string
      recommended: string
    }
    [ListingAttributes.BULLET_POINTS]?: {
      live: string[]
      recommended: string[]
    }
    [ListingAttributes.DESCRIPTION]?: {
      live: string
      recommended: string
    }
    [ListingAttributes.SEARCH_TERMS]?: {
      live: string
      recommended: string
    }
  }
    keywordsAdded: {
        keyword: string
        rating: number
        status: 'added' | 'kept' | 'removed' | 'unused'
    }[]
  }) => {
    stt.selectedListingPanel = payload;
  },
};

export const actions:ActionTree<RootState, RootState> = {
  updateListingPanel({
    commit,
  }, payload: {
    asin: string | null
    marketplaceId: string | null
    listingOptimizationEnabled: boolean
    listingAttributesToOptimize: ListingAttributes[
    ]
    listingOptimization: {
    [ListingAttributes.TITLE]?: {
      live: string
      recommended: string
    }
    [ListingAttributes.BULLET_POINTS]?: {
      live: string[]
      recommended: string[]
    }
    [ListingAttributes.DESCRIPTION]?: {
      live: string
      recommended: string
    }
    [ListingAttributes.SEARCH_TERMS]?: {
      live: string
      recommended: string
    }
  }
    keywordsAdded: {
        keyword: string
        rating: number
        status: 'added' | 'kept' | 'removed' | 'unused'
    }[]
  }) {
    commit(UPDATE_LISTING_PANEL, payload);
  },
  openListingPanel({
    commit,
  }, payload: {
    forceOptimization: boolean
    isOpportunity: boolean
    performanceFocus: boolean
  } = {
    isOpportunity: false,
    forceOptimization: false,
    performanceFocus: false,
  }) {
    commit(OPEN_LISTING_PANEL, payload);
  },
  closeListingPanel({
    commit,
  }) {
    commit(CLOSE_LISTING_PANEL);
  },
};

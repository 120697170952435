import {
  library,
} from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretUp,
  faExternalLink,
  faMinus,
  faSortUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faArrowAltCircleRight,
  faArrowCircleLeft,
  faArrowDownToLine,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowUpRightFromSquare,
  faBarsFilter,
  faBell,
  faBellSlash,
  faBolt,
  faCalendar,
  faCaretRight,
  faCartShopping,
  faCartShoppingFast,
  faChartLine,
  faCheckDouble,
  faChevronCircleRight,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faCoins,
  faDown,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faFileAlt,
  faFileDownload,
  faFilePlus,
  faFolderOpen,
  faGears,
  faHexagonExclamation,
  faHomeLgAlt,
  faInfoCircle,
  faKey,
  faList,
  faLock,
  faMagnifyingGlass,
  faNewspaper,
  faPaperPlane,
  faPaste,
  faPen,
  faPencil,
  faPercent,
  faPlus,
  faQuestion,
  faRobot,
  faRotateLeft,
  faSignInAlt,
  faSignOutAlt,
  faSquare,
  faSquareCheck,
  faThumbtack,
  faTrash,
  faUpFromBracket,
  faUser,
  faUserPlus,
  faXmark,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faEllipsisH,
  faGear,
} from '@fortawesome/pro-regular-svg-icons';
import {
  FontAwesomeIcon,
} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(
  faPaste,
  faCircleXmark,
  faSignInAlt,
  faRotateLeft,
  faTimes,
  faNewspaper,
  faPlus,
  faMinus,
  faSortUp,
  faUpFromBracket,
  faCaretDown,
  faUserPlus,
  faChevronLeft,
  faPen,
  faDown,
  faChevronCircleRight,
  faChevronRight,
  faFileDownload,
  faSignOutAlt,
  faTrash,
  faHomeLgAlt,
  faCaretRight,
  faFolderOpen,
  faGear,
  faBolt,
  faThumbtack,
  faCartShopping,
  faCartShoppingFast,
  faGears,
  faSquareCheck,
  faCheckDouble,
  faCalendar,
  faArrowUpRightFromSquare,
  faArrowDownWideShort,
  faBell,
  faList,
  faQuestion,
  faArrowDownToLine,
  faFileAlt,
  faBarsFilter,
  faCircleCheck,
  faXmark,
  faUser,
  faCircle,
  faLock,
  faArrowCircleLeft,
  faSquare,
  faChevronsLeft,
  faCircleQuestion,
  faChevronsRight,
  faClock,
  faAngleUp,
  faAngleDown,
  faFilePlus,
  faChartLine,
  faEnvelope,
  faBellSlash,
  faPaperPlane,
  faCircleExclamation,
  faRobot,
  faInfoCircle,
  faEllipsisH,
  faCheck,
  faCoins,
  faArrowAltCircleRight,
  faPercent,
  faExclamation,
  faExclamationTriangle,
  faHexagonExclamation,
  faCaretUp,
  faArrowLeft,
  faKey,
  faMagnifyingGlass,
  faPencil,
  faExternalLink,
);

// default mappings: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/services/icons/presets/fa.ts
const values = {
};
[
  {
    key: 'folder',
    icon: [
      'fad',
      'folder-open',
    ],
  },
  {
    key: 'coins',
    icon: [
      'fad',
      'coins',
    ],
  },
  {
    key: 'key',
    icon: [
      'fad',
      'key',
    ],
  },
  {
    key: 'paste',
    icon: [
      'fad',
      'paste',
    ],
  },
  {
    key: 'signIn',
    icon: [
      'fad',
      'sign-in-alt',
    ],
  },
  {
    key: 'signOut',
    icon: [
      'fad',
      'sign-out-alt',
    ],
  },
  {
    key: 'next',
    icon: [
      'far',
      'chevron-right',
    ],
  },
  {
    key: 'prev',
    icon: [
      'far',
      'chevron-left',
    ],
  },
  {
    key: 'last',
    icon: [
      'far',
      'chevrons-right',
    ],
  },
  {
    key: 'first',
    icon: [
      'far',
      'chevrons-left',
    ],
  },
  {
    key: 'download',
    icon: [
      'fad',
      'file-download',
    ],
  },
  {
    key: 'home',
    icon: [
      'fad',
      'home-lg-alt',
    ],
  },
  {
    key: 'file',
    icon: [
      'fad',
      'file-alt',
    ],
  },
  {
    key: 'lock',
    icon: [
      'fad',
      'lock',
    ],
  },
  {
    key: 'arrow-left',
    icon: [
      'fad',
      'arrow-left',
    ],
  },
  {
    key: 'file-plus',
    icon: [
      'fad',
      'file-plus',
    ],
  },
  {
    key: 'info-circle',
    icon: [
      'fad',
      'info-circle',
    ],
  },
  {
    key: 'ellipsis-h',
    icon: [
      'far',
      'ellipsis-h',
    ],
  },
  {
    key: 'check',
    icon: [
      'far',
      'check',
    ],
  },
  {
    key: 'chevron-circle-right',
    icon: [
      'fad',
      'chevron-circle-right',
    ],
  },
  {
    key: 'caret-down',
    icon: [
      'fas',
      'caret-down',
    ],
  },
  {
    key: 'caret-up',
    icon: [
      'fas',
      'caret-up',
    ],
  },
  {
    key: 'exclamation-triangle',
    icon: [
      'fad',
      'exclamation-triangle',
    ],
  },
  {
    key: 'hexagon-exclamation',
    icon: [
      'fad',
      'hexagon-exclamation',
    ],
  },
  {
    key: 'magnifying-glass',
    icon: [
      'fad',
      'magnifying-glass',
    ],
  },
  {
    key: 'chart-line',
    icon: [
      'fad',
      'chart-line',
    ],
  },
  {
    key: 'percent',
    icon: [
      'fad',
      'percent',
    ],
  },
  {
    key: 'circle-check',
    icon: [
      'fad',
      'circle-check',
    ],
  },
  {
    key: 'circle',
    icon: [
      'fad',
      'circle',
    ],
  },
  {
    key: 'exclamation',
    icon: [
      'fad',
      'exclamation',
    ],
  },
  {
    key: 'circle-xmark',
    icon: [
      'fad',
      'circle-xmark',
    ],
  },
  {
    key: 'circle-exclamation',
    icon: [
      'fad',
      'circle-exclamation',
    ],
  },
  {
    key: 'envelope',
    icon: [
      'fad',
      'envelope',
    ],
  },
  {
    key: 'paper-plane',
    icon: [
      'fad',
      'paper-plane',
    ],
  },
  {
    key: 'plus',
    icon: [
      'fad',
      'plus',
    ],
  },
  {
    key: 'pencil',
    icon: [
      'fad',
      'pencil',
    ],
  },
  {
    key: 'arrow-down-to-line',
    icon: [
      'fad',
      'arrow-down-to-line',
    ],
  },
  {
    key: 'bars-filter',
    icon: [
      'fad',
      'bars-filter',
    ],
  },
  {
    key: 'calendar',
    icon: [
      'fad',
      'calendar',
    ],
  },
  {
    key: 'square-check',
    icon: [
      'fad',
      'square-check',
    ],
  },
  {
    key: 'square',
    icon: [
      'fad',
      'square',
    ],
  },
  {
    key: 'trash',
    icon: [
      'fad',
      'trash',
    ],
  },
  {
    key: 'xmark',
    icon: [
      'fad',
      'xmark',
    ],
  },
  {
    key: 'angle-up',
    icon: [
      'fad',
      'angle-up',
    ],
  },
  {
    key: 'angle-down',
    icon: [
      'fad',
      'angle-down',
    ],
  },
  {
    key: 'gear',
    icon: [
      'far',
      'gear',
    ],
  },
  {
    key: 'gears',
    icon: [
      'fad',
      'gears',
    ],
  },
  {
    key: 'arrow-up-right-from-square',
    icon: [
      'fad',
      'arrow-up-right-from-square',
    ],
  },
  {
    key: 'list',
    icon: [
      'fad',
      'list',
    ],
  },
  {
    key: 'check-double',
    icon: [
      'fad',
      'check-double',
    ],
  },
  {
    key: 'clock',
    icon: [
      'fad',
      'clock',
    ],
  },
  {
    key: 'bell-slash',
    icon: [
      'fad',
      'bell-slash',
    ],
  },
  {
    key: 'bell',
    icon: [
      'fad',
      'bell',
    ],
  },
  {
    key: 'question',
    icon: [
      'fad',
      'question',
    ],
  },
  {
    key: 'bolt',
    icon: [
      'fad',
      'bolt',
    ],
  },
  {
    key: 'cart-shopping',
    icon: [
      'fad',
      'cart-shopping',
    ],
  },
  {
    key: 'cart-shopping-fast',
    icon: [
      'fad',
      'cart-shopping-fast',
    ],
  },
  {
    key: 'down',
    icon: [
      'fad',
      'down',
    ],
  },
  {
    key: 'arrow-down-wide-short',
    icon: [
      'fad',
      'arrow-down-wide-short',
    ],
  },
  {
    key: 'caret-right',
    icon: [
      'fad',
      'caret-right',
    ],
  },
  {
    key: 'user',
    icon: [
      'fad',
      'user',
    ],
  },
  {
    key: 'external-link',
    icon: [
      'fad',
      'external-link',
    ],
  },
  {
    key: 'user-plus',
    icon: [
      'fad',
      'user-plus',
    ],
  },
  {
    key: 'circle-question',
    icon: [
      'fad',
      'circle-question',
    ],
  },
  {
    key: 'thumbtack',
    icon: [
      'fad',
      'thumbtack',
    ],
  },
  {
    key: 'up-from-bracket',
    icon: [
      'fad',
      'up-from-bracket',
    ],
  },
  {
    key: 'robot',
    icon: [
      'fad',
      'robot',
    ],
  },
  {
    key: 'rotate-left',
    icon: [
      'fad',
      'rotate-left',
    ],
  },
  {
    key: 'pen',
    icon: [
      'fad',
      'pen',
    ],
  },
  {
    key: 'newspaper',
    icon: [
      'fad',
      'newspaper',
    ],
  },
].forEach(({
  key, icon,
}) => {
  values[key] = {
    component: FontAwesomeIcon,
    props: {
      icon,
    },
  };
});

export default {
  theme: {
    dark: true,
    themes: {
      dark: {
        accent: '#56DB8D',
        error: '#ffcece',
        warning: '#ff8a00',
        default: '#eaf0fb',
        primary: '#D0D6FB',
        'mustard-yellow': '#f7d468',
        dark: '#111633',
        secondary: '#8188af',
        failure: '#ff4a4a',
        danger: '#ff4a4a',
        success: '#37DF7D',
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
    values,
  },
};

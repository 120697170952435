import {
  InferType,
  number,
} from 'yup';

const Id = number().required();

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Id = InferType<typeof Id>

export default Id;

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _826451a2 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _523c031c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _3805727a = () => interopDefault(import('../pages/admin/organizations/index.vue' /* webpackChunkName: "pages/admin/organizations/index" */))
const _b7283a12 = () => interopDefault(import('../pages/admin/sellers/index.vue' /* webpackChunkName: "pages/admin/sellers/index" */))
const _f6257daa = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _1273a4cc = () => interopDefault(import('../pages/admin/organizations/_organizationIdOrSlug.vue' /* webpackChunkName: "pages/admin/organizations/_organizationIdOrSlug" */))
const _4d0a367e = () => interopDefault(import('../pages/admin/sellers/_sellerId.vue' /* webpackChunkName: "pages/admin/sellers/_sellerId" */))
const _7b4715fe = () => interopDefault(import('../pages/admin/users/_userId.vue' /* webpackChunkName: "pages/admin/users/_userId" */))
const _9012816a = () => interopDefault(import('../pages/admin-login.vue' /* webpackChunkName: "pages/admin-login" */))
const _77f3af77 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5db6bd30 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _09e460e5 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4b7235bf = () => interopDefault(import('../pages/notFound.vue' /* webpackChunkName: "pages/notFound" */))
const _5c8afe77 = () => interopDefault(import('../pages/oauth.vue' /* webpackChunkName: "pages/oauth" */))
const _369df0ae = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _58962f73 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _1b3c4c1f = () => interopDefault(import('../pages/shopify-auth.vue' /* webpackChunkName: "pages/shopify-auth" */))
const _dd401cfc = () => interopDefault(import('../pages/shopify-login.vue' /* webpackChunkName: "pages/shopify-login" */))
const _23563984 = () => interopDefault(import('../pages/shopify-sign-up.vue' /* webpackChunkName: "pages/shopify-sign-up" */))
const _1842588b = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _1ca98803 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId" */))
const _3be2c046 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/index.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/index" */))
const _6e19d790 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/account-health.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/account-health" */))
const _1e052098 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/advertising.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/advertising" */))
const _3cc0a1a2 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/brand-valuation.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/brand-valuation" */))
const _1af1d672 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/brand-valuation/index.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/brand-valuation/index" */))
const _e756e97a = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/brand-valuation/_brandName.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/brand-valuation/_brandName" */))
const _a1c3e460 = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/inventory.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/inventory" */))
const _20961e0e = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/operations-assistant.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/operations-assistant" */))
const _bd609f5a = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/product-overview.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/product-overview" */))
const _bf66d93c = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/profit-loss-statement.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/profit-loss-statement" */))
const _a124b89a = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/reports.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/reports" */))
const _0755e9df = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/settings.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/settings" */))
const _2a4c84ca = () => interopDefault(import('../pages/dashboard/sellers/_sellerId/weekly-insights.vue' /* webpackChunkName: "pages/dashboard/sellers/_sellerId/weekly-insights" */))
const _6002c798 = () => interopDefault(import('../pages/invites/_aggregatorSlugifiedName.vue' /* webpackChunkName: "pages/invites/_aggregatorSlugifiedName" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _826451a2,
    children: [{
      path: "",
      component: _523c031c,
      name: "admin"
    }, {
      path: "organizations",
      component: _3805727a,
      name: "admin-organizations"
    }, {
      path: "sellers",
      component: _b7283a12,
      name: "admin-sellers"
    }, {
      path: "users",
      component: _f6257daa,
      name: "admin-users"
    }, {
      path: "organizations/:organizationIdOrSlug",
      component: _1273a4cc,
      name: "admin-organizations-organizationIdOrSlug"
    }, {
      path: "sellers/:sellerId",
      component: _4d0a367e,
      name: "admin-sellers-sellerId"
    }, {
      path: "users/:userId",
      component: _7b4715fe,
      name: "admin-users-userId"
    }]
  }, {
    path: "/admin-login",
    component: _9012816a,
    name: "admin-login"
  }, {
    path: "/dashboard",
    component: _77f3af77,
    name: "dashboard"
  }, {
    path: "/error",
    component: _5db6bd30,
    name: "error"
  }, {
    path: "/forgot-password",
    component: _09e460e5,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/notFound",
    component: _4b7235bf,
    name: "notFound"
  }, {
    path: "/oauth",
    component: _5c8afe77,
    name: "oauth"
  }, {
    path: "/reset-password",
    component: _369df0ae,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _58962f73,
    name: "settings"
  }, {
    path: "/shopify-auth",
    component: _1b3c4c1f,
    name: "shopify-auth"
  }, {
    path: "/shopify-login",
    component: _dd401cfc,
    name: "shopify-login"
  }, {
    path: "/shopify-sign-up",
    component: _23563984,
    name: "shopify-sign-up"
  }, {
    path: "/sign-up",
    component: _1842588b,
    name: "sign-up"
  }, {
    path: "/dashboard/sellers/:sellerId?",
    component: _1ca98803,
    children: [{
      path: "",
      component: _3be2c046,
      name: "dashboard-sellers-sellerId"
    }, {
      path: "account-health",
      component: _6e19d790,
      name: "dashboard-sellers-sellerId-account-health"
    }, {
      path: "advertising",
      component: _1e052098,
      name: "dashboard-sellers-sellerId-advertising"
    }, {
      path: "brand-valuation",
      component: _3cc0a1a2,
      children: [{
        path: "",
        component: _1af1d672,
        name: "dashboard-sellers-sellerId-brand-valuation"
      }, {
        path: ":brandName",
        component: _e756e97a,
        name: "dashboard-sellers-sellerId-brand-valuation-brandName"
      }]
    }, {
      path: "inventory",
      component: _a1c3e460,
      name: "dashboard-sellers-sellerId-inventory"
    }, {
      path: "operations-assistant",
      component: _20961e0e,
      name: "dashboard-sellers-sellerId-operations-assistant"
    }, {
      path: "product-overview",
      component: _bd609f5a,
      name: "dashboard-sellers-sellerId-product-overview"
    }, {
      path: "profit-loss-statement",
      component: _bf66d93c,
      name: "dashboard-sellers-sellerId-profit-loss-statement"
    }, {
      path: "reports",
      component: _a124b89a,
      name: "dashboard-sellers-sellerId-reports"
    }, {
      path: "settings",
      component: _0755e9df,
      name: "dashboard-sellers-sellerId-settings"
    }, {
      path: "weekly-insights",
      component: _2a4c84ca,
      name: "dashboard-sellers-sellerId-weekly-insights"
    }]
  }, {
    path: "/invites/:aggregatorSlugifiedName?",
    component: _6002c798,
    name: "invites-aggregatorSlugifiedName"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/invites",
    redirect: {"name":"dashboard"}
  }, {
    path: "/dashboard/sellers/:sellerId/brands",
    redirect: {"name":"dashboard-sellers-sellerId-brand-valuation"},
    name: "dashboard-sellers-sellerId-brands"
  }, {
    path: "/dashboard/sellers/:sellerId/momentum",
    redirect: {"name":"dashboard-sellers-sellerId-brand-valuation"},
    name: "dashboard-sellers-sellerId-momentum"
  }, {
    path: "/dashboard/sellers/:sellerId/brands/:brandName",
    redirect: {"name":"dashboard-sellers-sellerId-brand-valuation-brandName"},
    name: "dashboard-sellers-sellerId-brands-brandName"
  }, {
    path: "/dashboard/sellers/:sellerId/momentum/:brandName",
    redirect: {"name":"dashboard-sellers-sellerId-brand-valuation-brandName"},
    name: "dashboard-sellers-sellerId-momentum-brandName"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

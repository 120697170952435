type InputType = 'percentage' | 'decimal'

const formatValueForDisplay = (
  value: number,
  locale: string,
  maximumFractionDigits: number,
  inputType: InputType,
  addPercentageChar?: boolean,
) => {
  let formattedValue = Number(value);

  if (addPercentageChar) {
    if (inputType === 'percentage') {
      formattedValue = value * 100;
    }

    return `${formattedValue.toLocaleString(locale, {
      maximumFractionDigits,
    })}%`;
  }

  return formattedValue.toLocaleString(locale, {
    maximumFractionDigits,
  });
};

export default formatValueForDisplay;

import * as FullStory from '@fullstory/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Plugin,
} from '@nuxt/types';

const fullStoryPlugin:Plugin = ({
  app: {
    $config,
  },
}) => {
  FullStory.init({
    orgId: $config.fullStory.orgId,
    devMode: !$config.fullStory.productionMode,
  });
};

export default fullStoryPlugin;

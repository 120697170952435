import {
  NOTIFICATION_TYPES,
} from '@/constants';

const ALERT_TEXTS = {
  REPORT_DOWNLOAD_ERROR: 'Your report could not be generated.',
  SIGN_UP_ERROR: 'Sorry, we couldn’t complete your account creation.',
  GENERIC_ERROR: 'Something went wrong.',
};

const errorTemplate = ({
  text, config: {
    supportEmail,
  },
}) => `
  <span class="text-dark">${text}
  <br />
  please contact us at
    <a href="mailto:${supportEmail}">
      <strong class="text-error">${supportEmail}</strong>
    </a>
  </span>
`;

export default {
  deleteNotification({
    commit,
  }) {
    commit('deleteNotification');
  },
  setReportDownloadError({
    commit,
  }) {
    const notification = {
      message: errorTemplate({
        config: this.$config,
        text: ALERT_TEXTS.REPORT_DOWNLOAD_ERROR,
      }),
      html: true,
      type: NOTIFICATION_TYPES.ERROR,
    };

    commit('setNotification', notification);
  },
  setSignupError({
    commit,
  }) {
    const notification = {
      message: errorTemplate({
        config: this.$config,
        text: ALERT_TEXTS.SIGN_UP_ERROR,
      }),
      html: true,
      type: NOTIFICATION_TYPES.ERROR,
    };

    commit('setNotification', notification);
  },
  setSuccess({
    commit,
  }, message) {
    const notification = {
      message,
      html: true,
      type: NOTIFICATION_TYPES.SUCCESS,
    };

    commit('setNotification', notification);
  },
  setGenericError({
    commit,
  }, message) {
    const notification = {
      message: message || errorTemplate({
        config: this.$config,
        text: ALERT_TEXTS.GENERIC_ERROR,
      }),
      html: true,
      type: NOTIFICATION_TYPES.ERROR,
    };

    commit('setNotification', notification);
  },
  setNotification({
    commit, state,
  }, notification) {
    if (Object.keys(state.notification).length) {
      commit('deleteNotification', notification);
      setTimeout(() => {
        commit('setNotification', notification);
      }, 750);
    } else {
      commit('setNotification', notification);
    }
  },
};

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';

import {
  NOTIFICATION_TYPES,
} from '@/constants';

const emailVerificationMiddleware: Middleware = async ({
  route,
  $api,
  $cookies,
  $sentry,
}) => {
  if (route.query.evt) {
    const {
      query: {
        evt,
      },
    } = route;

    try {
      const {
        data: {
          activatedUser,
        },
      } = await $api.post(`/email-verifications/${evt}`);

      if (!activatedUser.activated) {
        throw new Error('User wasn\'t activated');
      }

      $cookies.set('flash', {
        type: NOTIFICATION_TYPES.DEFAULT,
        message: 'Your account is now activated.',
      });
    } catch (error) {
      $sentry.captureException(error);
      $cookies.set('flash', {
        type: NOTIFICATION_TYPES.ERROR,
        message: 'Your email address could not get verified.',
      });
    }
  }
};

export default emailVerificationMiddleware;

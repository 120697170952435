import Vue from 'vue';

import {
  initialState,
} from './state';

export default {
  setNotification(state, notification) {
    Vue.set(state, 'notification', notification);
  },
  deleteNotification(state) {
    Vue.set(state, 'notification', initialState);
  },
};

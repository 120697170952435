export default [
  'EUR',
  'GBP',
  'USD',
  'AED',
  'ALL',
  'ARS',
  'AUD',
  'BDT',
  'BRL',
  'BGN',
  'CAD',
  'CHF',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JOD',
  'JPY',
  'KHR',
  'KRW',
  'LAK',
  'LKR',
  'MAD',
  'MXN',
  'MYR',
  'NGN',
  'NOK',
  'NPR',
  'NZD',
  'PHP',
  'PKR',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TWD',
  'TRY',
  'VND',
  'ZAR',
] as const;

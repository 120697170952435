import {
  InferType,
  string,
} from 'yup';

import AppTokenAuthAppNameEnum from '@/enums/AppTokenAuthAppName';

const AppTokenAuthAppName = string().required().oneOf(Object.keys(AppTokenAuthAppNameEnum), 'Unsupported app');

// eslint-disable-next-line @typescript-eslint/no-redeclare
type AppTokenAuthAppName = InferType<typeof AppTokenAuthAppName> & `${AppTokenAuthAppNameEnum}`

export default AppTokenAuthAppName;

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Plugin,
} from '@nuxt/types';
import {
  NuxtAxiosInstance,
} from '@nuxtjs/axios';
import qs from 'qs';

import AdminApiClient from './AdminApiClient';
import ApiClient from './ApiClient';

declare module 'vue/types/vue' {
  interface Vue {
    $api: NuxtAxiosInstance
    $apiClient: ApiClient
    $adminApiClient: AdminApiClient
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $api: NuxtAxiosInstance
    $apiClient: ApiClient
    $adminApiClient: AdminApiClient
  }
  interface Context {
    $api: NuxtAxiosInstance
    $apiClient: ApiClient
    $adminApiClient: AdminApiClient
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $api: NuxtAxiosInstance
  }
}

const $sellerReportingApi = (apiClient: NuxtAxiosInstance) => {
  const sendEmailVerificationEmail = () => apiClient.$post('/email-verifications');

  return {
    sendEmailVerificationEmail,
  };
};

const api: Plugin = ({
  $axios,
}, inject) => {
  const baseUrl = `${$axios.defaults.baseURL}/api`;
  const $api = $axios.create({
    baseURL: `${$axios.defaults.baseURL}/api`,
    paramsSerializer: (params) => qs.stringify(params, {
      arrayFormat: 'brackets',
    }),
  });

  inject('api', $api);
  inject('apiClient', new ApiClient($api));
  inject('adminApiClient', new AdminApiClient($api, baseUrl));
  inject('sellerReportingApi', $sellerReportingApi($api));
};

export default api;

export default (obj: Record<string, any>) => Object.keys(obj).reduce((agg, key) => {
  const value = obj[key];
  if (!value) {
    return agg;
  }
  return {
    ...agg,
    [key]: value,
  };
}, {
} as Record<string, any>);

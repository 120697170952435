import {
  NuxtAxiosInstance,
} from '@nuxtjs/axios';
import {
  AxiosResponse,
} from 'axios';
import qs from 'qs';

import {
  GetOptimizedListing,
  OptimizationState,
  SendFeedbackForOptimizedListing,
  VaryingAttributes,
} from '@/api/clients/CopywriterApi.types';
import {
  AlertActionPayload,
  AlertSummary,
} from '@/api/clients/SellerAlertingApi.types';
import {
  AmazonAdKpis,
  GetSellerProducts,
  OperationsPnl,
  WeeklyMetricPerformance,
} from '@/api/clients/sellerDataApi.types';
import {
  AlertTypeIds, KeywordOpportunityValueDetails,
} from '@/constants/alertConfig';
import ListingAttributes from '@/enums/ListingAttributes';
import compactObject from '@/helpers/compactObject';
import BrandConfiguration, {
  CreateBrandConfigurationPayload,
} from '@/interfaces/BrandConfiguration';
import {
  Seller,
} from '@/models';

type ProfitAndLossStatementHeaders = {
  text: string;
  value: string;
}[]

type ProfitAndLossStatementItems = Record<string, number | string | undefined>[]

export type ProfitAndLossStatement = {
  items: ProfitAndLossStatementItems,
  headers: ProfitAndLossStatementHeaders
}

export interface SellerAccount {
  id: string
  isAuthorized: boolean
  name: string,
  meta: string
  provider: string
  region: string
  regionName: string
}

export interface GetSellerStatusResponse {
  accounts: SellerAccount[]
}

export interface RequestPasswordResetLinkPayload {
  username: string;
}

export interface ResetPasswordPayload {
  password: string;
  token: string
}
export interface GetSalesMetricsParams {
  targetCurrency: string
}

export interface GetFundingEstimateForSellerParams {
  targetCurrency: string
}

export interface GetSellersResponse {
  sellers: {
    id: number
    name: string | null
    slug: string
  }[],
}

export interface GetProductCatalogParams {
  sellerSlug: string
}

interface CreateUserBasePayload {
  email: string
  password: string
  firstName: string
  lastName: string
  confirmedPassword: string
}

export interface CreateSellerUserPayload extends CreateUserBasePayload {
  sellerName: string
}

type CreateOrganizationUserPayload = CreateUserBasePayload

export type WeeklyPerformanceMetricType = 'units-sold' | 'average-selling-price' | 'reviews' | 'bsr' | 'returns' | 'listing-performance' | 'paid-to-organic-ration' | 'advertising-spend' | 'discounts' | 'net-margin'

export type SubscriptionPrice = {
  name: string,
  id: string,
  currency: string,
  interval: 'month' | 'year',
  unitAmount: number,
  trialPeriodDays: number
}

interface GetProductDetailsParams {
  targetCurrency: string
  currentPage?: number
  productsPerPage?: number
  search?: string
  startDate?: Date
  endDate?: Date
  sortBy?: string
  excludedAsin?: string
}
interface GetSelectedProductDetailsParams {
  targetCurrency: string
  marketplaceId: string
  startDate?: string
  endDate?: string
}
interface GetProductChartParams {
  categoryLevel?: 'main' | 'sub'
  asin: string
  marketplaceId: string
  startDate?: string
  endDate?: String
}
interface GetBenchmarkDataPointsParams {
  asin: string
  marketplaceId: string
  startDate: string
  endDate: string
}

export default class ApiClient {
  api:NuxtAxiosInstance;

  constructor(api:NuxtAxiosInstance) {
    this.api = api;
  }

  static parseError(err:any) {
    if (!err.isAxiosError) {
      return err;
    }

    if (err.response?.status === 422) {
      const errorResponse422 = err.response.data?.error as {
        details?: {
          errors?: string[]
        }
      };

      if (errorResponse422.details) {
        if (errorResponse422.details.errors) {
          return errorResponse422.details.errors.join(',');
        }
      }
    } else if (err.response?.status === 500) {
      const errorResponse500 = err.response.data?.error as {
        message: string,
        type: string
      };

      return errorResponse500.message;
    } else if (err.response?.status === 401) {
      const errorResponse401 = err.response.data?.message as string;

      return errorResponse401;
    }

    return err;
  }

  async login({
    username,
    password,
  }: {
    username: string
    password: string
  }) {
    try {
      const {
        data,
      } = await this.api.post('/auth/login/', {
        username,
        password,
      }) as AxiosResponse<{
        redirectToOauth: boolean
      } & Record<string, any>>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getBrandsFinancialOverview(
    sellerId: number | null,
    targetCurrency:string,
    aggregationView?: string,
    version:string | (string | null)[] = '1',
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/brands-overview`, {
        params: {
          targetCurrency,
          aggregationView,
          version,
        },
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellerAccountHealth(sellerId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/account-health`) as AxiosResponse<{
        statusByMarketplaces: any[]
      }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellerAccountHealthKpis(sellerId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/account-health/kpis`) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getBrandsAmazonAdvertisingOverview(
    sellerId: number | null,
    targetCurrency:string,
    aggregationView?: string,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/ad-metrics`, {
        params: {
          targetCurrency,
          aggregationView,
        },
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getPerformanceIndicators(sellerId: number, {
    brandName,
    targetCurrency,
  }: {
    brandName: string,
    targetCurrency:string
  }, version: string) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/performance`, {
        params: {
          targetCurrency,
          brandName,
          version,
        },
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProfitability(sellerSlug: string, brandName: string, targetCurrency:string) {
    try {
      const {
        data,
      } = await this.api.get(`/sellers/${sellerSlug}/brands/${brandName}/profitability`, {
        params: {
          targetCurrency,
        },
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProfitabilityV2(sellerId: string, brandName: string, targetCurrency:string) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/brands/${brandName}/profitability`, {
        params: {
          targetCurrency,
        },
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getCustomerSatisfaction(sellerId: string, brandName: string) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/brands/${brandName}/customer-satisfaction`) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async requestPasswordResetLink(payload:RequestPasswordResetLinkPayload) {
    try {
      const {
        data,
      } = await this.api.post('/auth/request-password-reset-link/', payload) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async resetPassword(payload:ResetPasswordPayload) {
    try {
      const {
        data,
      } = await this.api.post('/auth/reset-password/', payload) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async sendOpenToOffersEmail(sellerId: number) {
    try {
      await this.api.post(`/v2/sellers/${sellerId}/open-to-offers`) as AxiosResponse<any>;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async sendOpenToFundingEmail(sellerId: number) {
    try {
      await this.api.post(`/v2/sellers/${sellerId}/open-to-funding`) as AxiosResponse<any>;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSalesMetrics(
    sellerId: number,
    params:GetSalesMetricsParams,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/sales-metrics`, {
        params,
      }) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getMarketplaces(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/marketplaces`) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSeller(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}`) as AxiosResponse<Seller>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellerStatus(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/status`) as AxiosResponse<GetSellerStatusResponse>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellerBrands(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/brands`) as AxiosResponse<{
        brands: {
          name: string
        }[]
      }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProductsCatalog(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/products-catalog`) as AxiosResponse<any>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProductDetails(sellerId: number, params: GetProductDetailsParams) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/product-details`, {
        params,
      }) as AxiosResponse<any>;

      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSelectedProductDetails(
    sellerId: number,
    asin: string,
    params: GetSelectedProductDetailsParams,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/product-details/${asin}`, {
        params,
      }) as AxiosResponse<any>;

      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProductChart(sellerId: number, params: GetProductChartParams) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/product-chart/`, {
        params,
      }) as AxiosResponse<any>;

      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getBenchmarkDataPoints(params: GetBenchmarkDataPointsParams) {
    try {
      const {
        data,
      } = await this.api.get('/v2/benchmark-data-points/', {
        params,
      }) as AxiosResponse<any>;

      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getFundingEstimateForSeller(
    sellerId: number,
    params:GetFundingEstimateForSellerParams,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/funding-estimate`, {
        params,
      }) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellersForCurrentUser() {
    try {
      const {
        data,
      } = await this.api.get('/v2/sellers') as AxiosResponse<GetSellersResponse>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAggregatorInviteUrl() {
    try {
      const {
        data,
      } = await this.api.get('/invites') as AxiosResponse<{ inviteUrl: string }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async verifyAggregatorInviteUrl(token:string) {
    try {
      const {
        data,
      } = await this.api.post('/invites', {
        token,
      }) as AxiosResponse<{ aggregaorName: string }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getServiceAuthrizationUrl(sellerId: number, payload:{
    prvider: string,
    region?: string
    accountId?: string
    shopDomainName?: string
  }) {
    try {
      const {
        data,
      } = await this.api.post(`/v2/sellers/${sellerId}/service-authorization-url/`, payload) as AxiosResponse<{ authorizationUrl: string }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getServiceProviderOptions(sellerId: number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/service-provider-options/`) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProfitLossData(sellerId: number, params: {
    targetCurrency: string
    startDate: string
    endDate: string
    brands?: string[]
    products?: string
    martketplaces?: string[]
  }) {
    try {
      const {
        data,
      } = await this.api.get<object, AxiosResponse<{
        statement: ProfitAndLossStatement,
        startDate: string
        endDate: string
      }>>(`/v2/sellers/${sellerId}/profit-loss`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAsinProfitLossStatement(sellerId: number, params: {
    targetCurrency: string
    martketplaces?: string[]
    view?: 'MONTH' | 'WEEK' | 'DAY' | 'YEAR'
    startDate?: string,
    endDate?: string
  }, version?: string) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/asin-profit-loss`, {
        params: {
          ...params,
          version,
        },
      }) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSellerResources(sellerId: string, params: {
    brands: string[]
    products: string
    marketplaces: string[]
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/resources`, {
      params,
    }) as AxiosResponse<any>;

    return data;
  }

  async getSellerProducts(sellerId: number) {
    const {
      data,
    } = await this.api.get<any, AxiosResponse<GetSellerProducts.Response>>(
      `/v2/sellers/${sellerId}/resources/products`,
    );

    return data;
  }

  async registerNewUser(payload:CreateOrganizationUserPayload, token:string) {
    const {
      data,
    } = await this.api.post('/auth/register/user', {
      user: payload,
      token,
    });

    return data;
  }

  async registerNewSeller(payload:CreateSellerUserPayload, token:string) {
    const {
      data,
    } = await this.api.post('/auth/register', {
      user: payload,
      token,
    });

    return data;
  }

  async authShopify(externalId: string) {
    const {
      data,
    } = await this.api.post('/auth/shopify', {
      externalId,
    }) as AxiosResponse<any>;

    return data;
  }

  async signUpForShopifyAuth(
    payload:CreateSellerUserPayload,
    externalId: string,
    aggregatorSlug:string,
  ) {
    const {
      data,
    } = await this.api.post('/auth/shopify/sign-up', {
      user: payload,
      externalId,
      aggregatorSlug,
    }) as AxiosResponse<any>;

    return data;
  }

  async getUserWithId(userId:number) {
    const {
      data,
    } = await this.api.get(`/users/${userId}`) as AxiosResponse<any>;

    return data;
  }

  async acceptAutopilotTermsAndConditions() {
    const {
      data,
    } = await this.api.post('/v2/user/accept-autopilot-terms-and-conditions');

    return data;
  }

  async updateDefaultCurrencyCodeforUserId(userId:number, defaultCurrencyCode:string) {
    const {
      data,
    } = await this.api.patch(`/users/${userId}`, {
      defaultCurrencyCode,
    }) as AxiosResponse<any>;

    return data;
  }

  async getWeeklyPerformanceMetricData(
    sellerId: number | null,
    metric: string,
    query: WeeklyMetricPerformance.Query,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/weekly-performance/metrics/${metric}`, {
        params: compactObject(query),
      }) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createSellerSyncShopifyAccountForSeller(sellerId: number, payload:{
    shopDomainName: string,
    token: string
  }) {
    try {
      const {
        data,
      } = await this.api.post(`/v2/sellers/${sellerId}/accounts/shopify`, payload) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createSellerForOrganization(userId: number, payload: {
    sellerName: string
  }) {
    try {
      const {
        data,
      } = await this.api.post(`v2/user/${userId}/add-seller-to-organization`, payload);

      return data;
    } catch (err: any) {
      if (err.response.data?.statusCode === 409) {
        return {
          status: err.response.data.statusCode,
          seller: err.response.data?.data.seller,
        };
      }
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async inviteUsersToOrganization(userId: number, payload: {
    emailAddresses: string[]
  }) {
    try {
      const {
        data,
      } = await this.api.post(`v2/user/${userId}/invite-users-to-organization`, payload);

      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getCogsForSellerId(sellerId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/cost-of-goods-sold`) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async uploadCogsForSellerId(sellerId:number, costOfGoodsSold: any[]) {
    try {
      const {
        data,
      } = await this.api.post(`/v2/sellers/${sellerId}/cost-of-goods-sold`, {
        costOfGoodsSold,
      }) as AxiosResponse<any>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAmazonAdvertisingCampaignsReport(
    sellerId: number,
    targetCurrency: string,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/reports/amazon/advertising/campaigns`, {
        params: {
          targetCurrency,
        },
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAmazonAdvertisingCampaigns(
    sellerId: number,
    params: { targetCurrency: string, startDate:string, endDate: string },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/advertising/amazon/campaigns`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAmazonAdvertisingAsins(
    sellerId:number,
    params: { targetCurrency: string, startDate:string, endDate: string },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/advertising/amazon/asins`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getProductStockRecommendationsForSeller(
    sellerId: number,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/product-stock-recommendations`);
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getSkuAlerts(
    sellerId:number,
    params: {
      targetCurrency: string,
      alertType: string,
      threshold: number,
      lastCompletedWeeks: number,
    },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/sku-alerts`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlertsSeller(
    sellerId: number,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/alerts/alerts-seller`);
      return data;
    } catch (err: any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlerts(
    sellerId:number,
    params: {
      page: number,
      perPage: number,
      status: string[],
      alertTypes: string[],
      marketplaces: string[],
      alertSearch: string | null,
      sortingOption: string
      alertTimeframe: string,
      muted?: boolean
    },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/alerts`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlertsByChildAsin(
    sellerId:number,
    params: {
      asins: string[]
      status: string[],
      alertTypes: string[],
      marketplaces: string[],
      alertTimeframe: string,
    },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/alerts/by-child-asin`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlertsBySubject(
    sellerId:number,
    params: {
      page: number
      perPage: number
      summary: boolean,
      subject: string,
      subjectType: 'ASIN' | 'SKU' | 'account',
      marketplaceId: string[]
      alertTypeId: AlertTypeIds[]
    },
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/alerts/by-subject`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateAlert(
    sellerId:number,
    payload: {
      alertId: number,
      status: string,
      bulk?: string,
    },
  ) {
    try {
      const {
        data,
      } = await this.api.put(`/v2/sellers/${sellerId}/alerts/update-alert`, payload);
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateAlertMutedState(
    sellerId:number,
    payload: {
      alertRuleId: number,
      subject: string,
      mutedState: string,
      mutedAlertId?: number | null
    },
  ) {
    try {
      const {
        data,
      } = await this.api.put(`/v2/sellers/${sellerId}/alerts/rules/muted`, payload);
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlertsRules(
    sellerId:number,
  ) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/alerts/rules`);
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateAlertsRule(
    sellerId:number,
    params: {
      alertRuleId: string
    },
  ) {
    try {
      const {
        data,
      } = await this.api.put(`/v2/sellers/${sellerId}/alerts/rules`, {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getAlertTypes() {
    try {
      const {
        data,
      } = await this.api.get('/v2/sellers/alert-types');
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getCogsStatusForSeller(sellerId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/sellers/${sellerId}/cogs-status`);
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getCogsStatusForMultipleSelleres(externalIds:string[]) {
    try {
      const {
        data,
      } = await this.api.get('/v2/sellers/cogs-status', {
        params: {
          externalIds,
        },
        paramsSerializer: (params) => qs.stringify(params, {
          arrayFormat: 'repeat',
        }),
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getUserPofile() {
    const response = await this.api.post('/sessions/verification');
    return response.data;
  }

  async getEmailSubscriptionSettings() {
    const response = await this.api.get<{}, AxiosResponse<
      Record<string, boolean>
      >>('/v2/user/email-settings');

    return response.data;
  }

  async updateEmailSubscriptionSettings(emailSettings:Record<string, boolean>) {
    const response = await this.api.put('/v2/user/email-settings', {
      emailSettings,
    });
    return response;
  }

  async getSellersAlertReport(params: {
      externalIds: string[]
    }) {
    const {
      data,
    } = await this.api.get('/v2/sellers/alert-report', {
      params,
    }) as AxiosResponse<{
      report: AlertSummary,
      totals: {
        alertTotal: number,
        impactTotal: number,
      }
    }>;

    return data;
  }

  async createPaymentSession({
    priceId,
  }:{
    priceId:string
  }) {
    const response = await this.api.post<{}, AxiosResponse<{
      url:string
    }>>('/checkout/create-checkout-session', {
      priceId,
    });

    return response.data;
  }

  async createPortalSession() {
    const response = await this.api.post<{}, AxiosResponse<{
      url:string
    }>>('/checkout/create-portal-session');

    return response.data;
  }

  async getSubscriptionPrices() {
    const response = await this.api.get<{}, AxiosResponse<{ prices: SubscriptionPrice[]}>>(
      '/checkout/prices',
    );

    return response.data;
  }

  async getOAuthSession() {
    const response = await this.api.get<{}, AxiosResponse<{
      authorizeUrl: string
    }>>(
      '/oauth',
    );

    return response.data;
  }

  // Product Catalog Data (Historian API)
  async getEventList(sellerId: number, params: {
    asin: string,
    marketplaceId: string,
    before: string,
    after: string,
    type: 'listing' | 'images' | 'product_type' | 'sales_rank',
    filters: string[],
    perPage?: number,
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/product-historian/event-list`, {
      params,
    });

    return data;
  }

  async getListingOptimizationsPerSeller(sellerId: number, params: {
    page?: number
    perPage?: number
    failedOnly?: boolean
    unconfirmedOnly?: boolean
    search?: string
    asin?: string
    marketplaceId?: string
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/product-historian/listing-optimizations`, {
      params,
    });

    return data;
  }

  async getListingScore(sellerId: number, params: {
    asin: string,
    marketplaceId: string,
    skus: string[] | null,
    accountIds: string[]
    level: 'sku' | 'asin'
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/product-historian/listing-score`, {
      params,
    });

    return data;
  }

  async getCatalogData(sellerId: number, params: {
    asin: string,
    marketplaceId: string,
    skus: string[] | null,
    accountIds: string[]
    at?: Date
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/product-historian/catalog-data`, {
      params,
    });

    return data;
  }

  async getTopCatalogData(sellerId: number, params: {
    asin: string,
    marketplaceId: string,
  }) {
    const {
      data,
    } = await this.api.get(`/v2/sellers/${sellerId}/product-historian/top-catalog-data`, {
      params,
    });

    return data;
  }

  async postCatalogData(sellerId: number, payload: {
    asin:string
    marketplaceId: string
    title: string | string[] | null
    description: string | string[] | null
    keywords: string | string[] | null
    bulletPoints: string | string[] | null
    occurredKeywordsAdded?: number
    keywordsAdded?: {
      keyword: string
      rating: number
      status: 'added' | 'kept' | 'removed' | 'unused'
      campaignIds: number[]
      occurred?: boolean
  }[]
  }) {
    const {
      marketplaceId,
      asin,
    } = payload;
    const {
      data,
    } = await this.api.patch(
      `/v2/sellers/${sellerId}/product-historian/catalog-data/${marketplaceId}/${asin}`,
      {
        title: payload.title,
        description: payload.description,
        bulletPoints: payload.bulletPoints,
        keywords: payload.keywords,
        occurredKeywordsAdded: payload.occurredKeywordsAdded,
        keywordsAdded: payload.keywordsAdded,
      },
    );

    return data;
  }

  // Listing Optimizations (Copywriter API)
  async getOptimizedListing(sellerId: number, optimizedListingId: number) {
    const {
      data,
    } = await this.api.get<any, AxiosResponse<{
      id: number,
      state: OptimizationState.QUEUED | OptimizationState.COMPLETED | OptimizationState.FAILED
      optimization: {
        [ListingAttributes.TITLE]?: string
        [ListingAttributes.SEARCH_TERMS]?: string
        [ListingAttributes.BULLET_POINTS]?: string[]
        [ListingAttributes.DESCRIPTION]?: string
      }
      keywords: {
        keyword: string
        rating: number
        status: 'added' | 'kept' | 'removed' | 'unused'
        campaignIds: number[]
      }[]
      varyingAttributes: VaryingAttributes[]
      _raw: GetOptimizedListing.Response
    }>>(`/v2/sellers/${sellerId}/listing-optimizations/${optimizedListingId}`);

    return data;
  }

  async getChildAsinsVariations(sellerId: number, optimizedListingId: number) {
    const {
      data,
    } = await this.api.get<any, AxiosResponse<{
      title: string
      keywords: string
      bulletPoints: string[]
      description: string
      asin: string,
      skus: string[]
    }[]>>(`/v2/sellers/${sellerId}/listing-optimizations/${optimizedListingId}/variations`);

    return data;
  }

  async requestListingOptimization(
    sellerId: number,
    payload: {
      asin: string,
      marketplaceId: string,
      accountId: string,
      isParent: boolean
      asinBrandVoice: {
        officialName: string | null
        isFormal: boolean | null
        description: string | null
    }
    requestedListingItems?: ListingAttributes[]
  },
  ) {
    const {
      data,
    } = await this.api.post<any, AxiosResponse<{
      id: number,
      state: OptimizationState.QUEUED | OptimizationState.COMPLETED | OptimizationState.FAILED
    }>>(
      `/v2/sellers/${sellerId}/listing-optimizations`,
      payload,
    );

    return data;
  }

  async sendAcceptedListingItem(
    sellerId: number,
    optimizedListingId: number | null,
    listingKey: ListingAttributes,
    payload: {
      feedback: SendFeedbackForOptimizedListing.Feedback,
      valueBefore: string | string[] | null
      originalOptimization: string | string[] | null
      valueAfter: string | string[] | null
    },
  ) {
    try {
      const {
        data,
      } = await this.api.post<any, AxiosResponse<SendFeedbackForOptimizedListing.Response>>(
        `/v2/sellers/${sellerId}/listing-optimizations/${optimizedListingId}/accept/${listingKey}`,
        payload,
      );

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async sendFeedbackForOptimizedListing(
    sellerId: number,
    optimizedListingId: number | null,
    listingItem: ListingAttributes,
    payload: {
      feedback: SendFeedbackForOptimizedListing.Feedback,
      valueBefore: string | string[] | null
      originalOptimization: string | string[] | null
      valueAfter: string | string[] | null
    },
  ) {
    try {
      const {
        data,
      } = await this.api.post<any, AxiosResponse<SendFeedbackForOptimizedListing.Response>>(
        `/v2/sellers/${sellerId}/listing-optimizations/${optimizedListingId}/feedback/${listingItem}`,
        payload,
      );

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  }

  // Weekly Ops page
  async getOperationsPnl(sellerId: number, isFreePlan: boolean, query: OperationsPnl.Query) {
    const {
      data,
    } = await this.api.get<
      any,
      AxiosResponse<{ response: OperationsPnl.Response, report: {
        key: string,
        timeframes: { value: string, key: string, title: string, tooltip?: string }[]
      }[]}>
    >(`/v2/sellers/${sellerId}/profit-loss/operations`, {
      params: {
        ...compactObject(query),
        isFreePlan,
      },
    });

    return data;
  }

  async downloadOperationsPnl(sellerId: number, query: OperationsPnl.Query) {
    const {
      data,
    } = await this.api.get<
      any,
      AxiosResponse<{ response: OperationsPnl.Response, report: {
        key: string,
        timeframes: { value: string, key: string, title: string, tooltip?: string }[]
      }[]}>
    >(`/v2/sellers/${sellerId}/profit-loss/operations/report`, {
      params: compactObject(query),
      responseType: 'blob',
    });

    return data;
  }

  async getAmazonAdKpisForSeller(sellerId: number, query: AmazonAdKpis.Query) {
    const {
      data,
    } = await this.api.get<
      any,
      AxiosResponse<AmazonAdKpis.Response>
    >(`/v2/sellers/${sellerId}/ad-kpis`, {
      params: compactObject(query),
    });

    return data;
  }

  getBrandConfigurationsForSeller = async (sellerId: number) => {
    const response = await this.api.get<any, AxiosResponse<BrandConfiguration[]>>(`/v2/sellers/${sellerId}/brand-configurations`);
    return response.data;
  };

  async createBrandConfigurationForSeller(
    sellerId: number,
    payload:CreateBrandConfigurationPayload,
  ) {
    return this.api.post<any, AxiosResponse<{}>>(
      `/v2/sellers/${sellerId}/brand-configurations`,
      payload,
    );
  }

  async updateBrandConfiguration(
    sellerId: number,
    brandConfigurationId: number,
    payload:CreateBrandConfigurationPayload,
  ) {
    return this.api.put<any, AxiosResponse<{}>>(
      `/v2/sellers/${sellerId}/brand-configurations/${brandConfigurationId}`,
      payload,
    );
  }

  async deleteBrandConfiguration(sellerId: number, brandConfigurationId: number) {
    return this.api.delete<any, AxiosResponse<{}>>(
      `/v2/sellers/${sellerId}/brand-configurations/${brandConfigurationId}`,
    );
  }

  async submitActions(sellerId: number, payload: {
    actions: {
      details: KeywordOpportunityValueDetails & {
        asin: string
        listingAttributes: ListingAttributes[]
      }
      payload: AlertActionPayload
  }[],
  }) {
    return this.api.post(
      `/v2/sellers/${sellerId}/alerts/submit`,
      payload,
    );
  }

  async getPpcKeywordReportUrl(params: {
    sellerIds: string[]
    startDate?: Date
    endDate?: Date
  }) {
    try {
      const {
        data,
      } = await this.api.get('/v2/reports/ppc-report-url', {
        params,
      });
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }
}

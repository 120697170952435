enum Kpi {
  productComplexity = 'productComplexity',
  policyViolations = 'policyViolations',
  orderDefectRate = 'orderDefectRate',
  rating = 'rating',
  reviewVolume = 'reviewVolume',
  reviewQuality = 'reviewQuality',
  reviewQuota = 'reviewQuota',
  ebitdaYearly = 'ebitdaYearly',
  ebitdaMonthly = 'ebitdaMonthly',
  profitMargin = 'profitMargin',
  returnRate = 'returnRate',
  repeatCustomerRate = 'repeatCustomerRate',
  bestSellerRank = 'bestSellerRank',
  tacos = 'tacos',
  shopifyRevenueYoY = 'shopifyRevenueYoY'
}

export default Kpi;

export default (window: Window, params?: {
  name?: string,
  email?: string,
  subject?: string,
  text?: string,
}) => {
  window.Beacon('reset');
  window.Beacon('prefill', params);
  window.Beacon('toggle');
};

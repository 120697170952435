import Region from '@/enums/Region';

export const EU = 'eu';
export const NA = 'na';
export const FE = 'fe';

export default {
  [EU]: 'Europe & India',
  [NA]: 'North America',
  [FE]: 'Far East',
} as Record<Region, string>;

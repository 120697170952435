import {
  NuxtAxiosInstance,
} from '@nuxtjs/axios';
import {
  Aggregator,
} from '@prisma/client';
import axios, {
  AxiosResponse,
} from 'axios';

import {
  emailTypes,
} from '@/api/services/EmailSuppressionService';
import {
  InviteType,
} from '@/api/services/InviteService';
import Organization, {
  CreateOrganizationPayload,
  OrganizationAdminUpdatePayload,
  OrganizationDownload,
  OrganizationForAdminListView,
} from '@/interfaces/Organization';
import {
  CreateSellerForOrganizationPayload,
} from '@/interfaces/Seller';
import SellerOrganization from '@/interfaces/SellerOrganization';
import {
  UserUpdatePayload,
  UserWithEmailSuppressions,
  UserWithoutPassword,
} from '@/interfaces/User';
import {
  Seller,
} from '@/models';

export default class ApiClient {
  api:NuxtAxiosInstance;

  baseURL:string;

  constructor(api:NuxtAxiosInstance, baseURL: string) {
    this.api = api;
    this.baseURL = baseURL;
  }

  static parseError(err:any) {
    if (!err.isAxiosError) {
      return err;
    }

    if (err.response?.status === 422) {
      const errorResponse422 = err.response.data?.error as {
        details?: {
          errors?: string[]
        }
      };

      if (errorResponse422.details) {
        if (errorResponse422.details.errors) {
          return errorResponse422.details.errors.join(',');
        }
      }
    } else if (err.response?.status === 500) {
      const errorResponse500 = err.response.data?.error as {
        message: string,
        type: string
      };

      return errorResponse500.message;
    } else if (err.response?.status === 401) {
      const errorResponse401 = err.response.data?.error as string;

      return errorResponse401;
    }

    return err;
  }

  getLoginAsUserUrl(userId: number) {
    return `${this.baseURL}/v2/admin/users/${userId}/login`;
  }

  async getProfile() {
    const {
      data,
    } = await this.api.get('/v2/admin/profile') as AxiosResponse<any>;

    return data;
  }

  async getOrganizations() {
    const {
      data,
    } = await this.api.get('/v2/admin/organizations') as AxiosResponse<{
      organizations: OrganizationForAdminListView[]
    }>;

    return data;
  }

  async getOrganizationsWithUserEmailsAndSellerSlugs() {
    const {
      data,
    } = await this.api.get('/v2/admin/organizations/user-emails-seller-slugs') as AxiosResponse<{
      organizations: OrganizationDownload.UserEmailsAndSellerSlugs[]
    }>;

    return data;
  }

  async getSellers() {
    const {
      data,
    } = await this.api.get('/v2/admin/sellers') as AxiosResponse<{
      sellers: Seller[]
    }>;

    return data;
  }

  async getUsers() {
    const {
      data,
    } = await this.api.get('/v2/admin/users') as AxiosResponse<{
      users: UserWithEmailSuppressions[]
    }>;

    return data;
  }

  async getUser(userId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/users/${userId}`) as AxiosResponse<{
      user: UserWithoutPassword
    }>;

    return data;
  }

  async getSeller(sellerId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/sellers/${sellerId}`) as AxiosResponse<{
      seller: Seller
    }>;

    return data;
  }

  async getSellerAlertingAccount(sellerId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/admin/sellers/${sellerId}/alerting`) as AxiosResponse<any>;

      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          throw new Error('ALERTING_ACCOUNT_NOT_FOUND');
        }
      }

      throw err;
    }
  }

  async upsertSellerAlertingAccount(sellerId:number, payload: {
    currency: string
  }) {
    const {
      data,
    } = await this.api.post(`/v2/admin/sellers/${sellerId}/alerting`, payload) as AxiosResponse<any>;

    return data;
  }

  async getUsersForSeller(sellerId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/sellers/${sellerId}/users`) as AxiosResponse<{
      users: UserWithoutPassword[]
    }>;

    return data;
  }

  async getOrganization(organizationIdOrSlug:number|string) {
    const {
      data,
    } = await this.api.get(`/v2/admin/organizations/${organizationIdOrSlug}`) as AxiosResponse<{
      organization: Organization
      sellerSyncAdminUrl: string
    }>;
    return data;
  }

  async getUsersForOrganization(organizaionId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/organizations/${organizaionId}/users`) as AxiosResponse<{
      users: UserWithoutPassword[]
    }>;

    return data;
  }

  async getSellersForOrganization(organizaionId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/organizations/${organizaionId}/sellers`) as AxiosResponse<{
      sellers: Seller[]
    }>;

    return data;
  }

  async getSellersListingOptimizationEnabled(params?: {
    enabled?: boolean
  }) {
    const {
      data,
    } = await this.api.get('/v2/admin/sellers/listing-optimization-enabled', {
      params,
    }) as AxiosResponse<{
      sellers: Seller[]
    }>;

    return data;
  }

  async getOrganizationsForSeller(sellerId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/sellers/${sellerId}/organizations`) as AxiosResponse<(SellerOrganization & { aggregator: Aggregator })[]>;
    return data;
  }

  async updateOrganization(organizationId:number, payload: OrganizationAdminUpdatePayload) {
    try {
      const {
        data,
      } = await this.api.patch(`/v2/admin/organizations/${organizationId}`, payload) as AxiosResponse<{
      organization: Organization
    }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateOrganizationPlan(organizationId:number, payload: { plan: string }) {
    try {
      const {
        data,
      } = await this.api.patch(`/v2/admin/organizations/${organizationId}/plan`, payload) as AxiosResponse<{
      organization: Organization
    }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateUser(userId:number, payload: UserUpdatePayload) {
    try {
      const {
        data,
      } = await this.api.patch(`/v2/admin/users/${userId}`, payload) as AxiosResponse<{
      user: UserWithoutPassword
    }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async deleteUser(userId:number) {
    try {
      const {
        data,
      } = await this.api.delete(`/v2/admin/users/${userId}`) as AxiosResponse<{
      user: UserWithoutPassword
    }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateSellerForAdmin(sellerId:number, payload: {
    name: string,
    externalId: string,
    listingOptimizationEnabled: boolean}) {
    try {
      const {
        data,
      } = await this.api.patch(`/v2/admin/sellers/${sellerId}`, payload) as AxiosResponse<{
      seller: Seller
    }>;
      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async updateSellerOrganizationRelation(
    sellerId:number,
    organizaionId:number,
    payload: { organizaionOwnsSeller: boolean },
  ) {
    try {
      const response = await this.api.patch(
        `/v2/admin/sellers/${sellerId}/organizations/${organizaionId}`,
        payload,
      );
      return response;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async deleteSellerOrganizationRelation(
    sellerId:number,
    organizationId:number,
  ) {
    try {
      const response = await this.api.delete(
        `/v2/admin/sellers/${sellerId}/organizations/${organizationId}`,
      );
      return response;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async addSellerOrganizationRelation(
    sellerId:number,
    organizationId:number,
  ) {
    try {
      const response = await this.api.post(
        `/v2/admin/sellers/${sellerId}/organizations/${organizationId}`,
      );
      return response;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createOrganization(payload:CreateOrganizationPayload) {
    try {
      const {
        data,
      } = await this.api.post('/v2/admin/organizations', payload) as AxiosResponse<{
        organization: Organization,
      }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createSellerForOrganization(
    organizationId: number,
    payload:CreateSellerForOrganizationPayload,
  ) {
    try {
      const {
        data,
      } = await this.api.post(`/v2/admin/organizations/${organizationId}/add-seller`, payload) as AxiosResponse<{
        seller: Seller,
      }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createInviteForOrganization(organizaionId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/admin/organizations/${organizaionId}/invite`) as AxiosResponse<{
        inviteUrl: string,
      }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async createAllInviteForOrganization(organizaionId:number) {
    try {
      const {
        data,
      } = await this.api.get(`/v2/admin/organizations/${organizaionId}/invite-all`) as AxiosResponse<{
          id: InviteType,
          url: string,
        }[]
      >;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async sendDailyAlertSummary(userId: number) {
    try {
      const {
        data,
      } = await this.api.post(`/v2/admin/users/${userId}/send-daily-alert-summary`) as AxiosResponse<{
        emailAddress: string,
      }>;

      return data;
    } catch (err:any) {
      const parsedError = ApiClient.parseError(err);
      throw new Error(parsedError);
    }
  }

  async getUserEmailSubscriptionsSettings(userId:number) {
    const {
      data,
    } = await this.api.get(`/v2/admin/users/${userId}/email-settings`) as AxiosResponse<{
      settings: Record<string, string>
    }>;

    return data;
  }

  async updateUserEmailSubscriptionsSettings(userId:number, emailSettings:Record<string, boolean>) {
    const {
      data,
    } = await this.api.put(`/v2/admin/users/${userId}/email-settings`, {
      emailSettings,
    }) as AxiosResponse<{
      status: Record<string, string>
    }>;

    return data;
  }

  async getAllUsersEmailSubscriptionsSettings() {
    const {
      data,
    } = await this.api.get('/v2/admin/email-settings') as AxiosResponse<{
      emailSuppressions:
      Record<emailTypes, {
        id: number,
        userId: number,
        emailType: emailTypes
    }[]>
    }>;

    return data;
  }
}

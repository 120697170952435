export enum OrganizationPlans {
  DEFAULT = 'DEFAULT',
  PAID = 'PAID',
  ENTERPRISE = 'ENTERPRISE',
}

export const organizationPlanOptions = [
  {
    value: OrganizationPlans.DEFAULT,
    text: 'Default (Free)',
  },
  {
    value: OrganizationPlans.PAID,
    text: 'Paid',
  },
  {
    value: OrganizationPlans.ENTERPRISE,
    text: 'Enterprise',
  },
];


import beaconToggle from '@/helpers/beaconToggle';

export default {
  name: 'GraphicalWithNarrowContent',
  computed: {
    email() {
      const email = this.$store.getters['session/user']?.email || null;
      return email;
    },
    fullName() {
      const fullName = this.$store.getters['session/fullName'];
      return fullName;
    },
  },
  methods: {
    openHelpScout() {
      const {
        fullName,
        email,
      } = this;

      beaconToggle(window, {
        email,
        name: fullName,
      });
    },
  },
};

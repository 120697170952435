/* eslint-disable no-param-reassign */
import {
  UserOrganizationRoles,
} from '@prisma/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionTree, GetterTree, MutationTree,
} from 'vuex';

import {
  OrganizationPlans,
} from '@/constants/organizationPlans';
import createSentryContextForSession from '@/helpers/client/createSentryContextForSession';
import Organization from '@/interfaces/Organization';
import {
  UserWithoutPassword,
} from '@/interfaces/User';
import ApiClient from '@/plugins/ApiClient';

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $apiClient: ApiClient,
  }
}

export type RootState = {
  user: UserWithoutPassword | null
  organization: Organization | null
  isLoggedIn: boolean
  isAdminSession: boolean
  userRole: UserOrganizationRoles | null
}

export const state = ():RootState => ({
  user: null,
  organization: null,
  isLoggedIn: false,
  isAdminSession: false,
  userRole: null,
});

export const getters: GetterTree<RootState, RootState> = {
  user: (st) => st.user,
  fullName: (st) => [
    st.user?.firstName,
    st.user?.lastName,
  ].join(' '),
  organization: (st) => st.organization,
  isEnterprisePlan: (st) => {
    if (!st.organization) {
      return false;
    }
    return [
      OrganizationPlans.ENTERPRISE,
    ].includes(st.organization.plan);
  },
  acceptedAutopilotTnC: (st) => st.user?.acceptedAutopilotTnC,
  isFreePlan: (st) => {
    if (!st.organization) {
      return true;
    }
    return [
      OrganizationPlans.DEFAULT,
    ].includes(st.organization.plan);
  },
  isPaidPlan: (st) => {
    if (!st.organization) {
      return false;
    }
    return [
      OrganizationPlans.ENTERPRISE,
      OrganizationPlans.PAID,
    ].includes(st.organization.plan);
  },
  isSubscribed: (st) => {
    if (!st.organization) {
      return false;
    }
    return !!st.organization.stripeSubscriptionId;
  },
  isAdminSession: (st) => Boolean(st.isAdminSession),
  isOperationsUser: (st) => st.userRole === UserOrganizationRoles.OPERATIONS,
  isLoggedIn: (st) => st.isLoggedIn,
  isEmailVerified: (st) => st.user?.activated || false,
  defaultCurrencyCode: (st) => st.user?.defaultCurrencyCode || 'EUR',
};

export const mutations: MutationTree<RootState> = {
  delete(st) {
    st.isLoggedIn = false;
    st.isAdminSession = false;
    st.userRole = null;
    st.user = null;
    st.organization = null;
  },
  login(st, {
    user,
    organization,
    isAdminSession,
    userRole,
  }:{
    user: UserWithoutPassword,
    organization:Organization,
    isAdminSession: boolean,
    userRole: UserOrganizationRoles
  }) {
    st.isLoggedIn = true;
    st.isAdminSession = isAdminSession;
    st.user = user;
    st.organization = organization;
    st.userRole = userRole;
  },
  logout(st) {
    st.isLoggedIn = false;
    st.isAdminSession = false;
    st.user = null;
    st.userRole = null;
    st.organization = null;
  },
  updateUser(st, updatedUser:UserWithoutPassword) {
    st.user = updatedUser;
  },
};

export const actions:ActionTree<RootState, RootState> = {
  async login({
    commit,
  }, payload) {
    try {
      const {
        user,
        organization,
        isAdminSession,
        userRole,
        redirectLocation,
        inviteUrl,
      } = await this.$apiClient.login(payload);
      if (inviteUrl) {
        return window.location.assign(inviteUrl);
      }

      commit('login', {
        user,
        organization,
        isAdminSession,
        userRole,
      });

      await this.dispatch('sellers/get');

      createSentryContextForSession({
        user,
      });

      return redirectLocation;
    } catch (err) {
      throw new Error('Invalid session');
    }
  },
  async acceptAutopilotTermsAndConditions({
    commit,
  }) {
    try {
      const {
        user,
      } = await this.$apiClient.acceptAutopilotTermsAndConditions();
      commit('updateUser', user);
      return user;
    } catch (err) {
      throw new Error('Invalid session');
    }
  },

  async updateUser({
    commit,
  }, payload) {
    commit('updateUser', payload);
  },
  async logout({
    commit,
  }) {
    await this.$api.delete('/sessions');
    this.dispatch('sellers/clear');
    commit('logout');
  },
};

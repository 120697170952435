export const Action = () => import('../../components/Action.vue' /* webpackChunkName: "components/action" */).then(c => wrapFunctional(c.default || c))
export const ActionSection = () => import('../../components/ActionSection.vue' /* webpackChunkName: "components/action-section" */).then(c => wrapFunctional(c.default || c))
export const ActionsWidget = () => import('../../components/ActionsWidget.vue' /* webpackChunkName: "components/actions-widget" */).then(c => wrapFunctional(c.default || c))
export const AlertExploration = () => import('../../components/AlertExploration.vue' /* webpackChunkName: "components/alert-exploration" */).then(c => wrapFunctional(c.default || c))
export const AmazonAccountHealth = () => import('../../components/AmazonAccountHealth.vue' /* webpackChunkName: "components/amazon-account-health" */).then(c => wrapFunctional(c.default || c))
export const AmazonSpapiAuthDialog = () => import('../../components/AmazonSpapiAuthDialog.vue' /* webpackChunkName: "components/amazon-spapi-auth-dialog" */).then(c => wrapFunctional(c.default || c))
export const AssignOrganizationToSellerDialog = () => import('../../components/AssignOrganizationToSellerDialog.vue' /* webpackChunkName: "components/assign-organization-to-seller-dialog" */).then(c => wrapFunctional(c.default || c))
export const AutopilotUpdates = () => import('../../components/AutopilotUpdates.vue' /* webpackChunkName: "components/autopilot-updates" */).then(c => wrapFunctional(c.default || c))
export const BetaOrDemoChip = () => import('../../components/BetaOrDemoChip.vue' /* webpackChunkName: "components/beta-or-demo-chip" */).then(c => wrapFunctional(c.default || c))
export const BrandConfigurationDialog = () => import('../../components/BrandConfigurationDialog.vue' /* webpackChunkName: "components/brand-configuration-dialog" */).then(c => wrapFunctional(c.default || c))
export const BrandSettings = () => import('../../components/BrandSettings.vue' /* webpackChunkName: "components/brand-settings" */).then(c => wrapFunctional(c.default || c))
export const BrandValuationKpiSection = () => import('../../components/BrandValuationKpiSection.vue' /* webpackChunkName: "components/brand-valuation-kpi-section" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CardWidget = () => import('../../components/CardWidget.vue' /* webpackChunkName: "components/card-widget" */).then(c => wrapFunctional(c.default || c))
export const Caret = () => import('../../components/Caret.vue' /* webpackChunkName: "components/caret" */).then(c => wrapFunctional(c.default || c))
export const ChannelInsights = () => import('../../components/ChannelInsights.vue' /* webpackChunkName: "components/channel-insights" */).then(c => wrapFunctional(c.default || c))
export const ChartLoader = () => import('../../components/ChartLoader.vue' /* webpackChunkName: "components/chart-loader" */).then(c => wrapFunctional(c.default || c))
export const CogsStatusBanner = () => import('../../components/CogsStatusBanner.vue' /* webpackChunkName: "components/cogs-status-banner" */).then(c => wrapFunctional(c.default || c))
export const CollapseButton = () => import('../../components/CollapseButton.vue' /* webpackChunkName: "components/collapse-button" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationModal = () => import('../../components/ConfirmationModal.vue' /* webpackChunkName: "components/confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const CopyToClipboardButton = () => import('../../components/CopyToClipboardButton.vue' /* webpackChunkName: "components/copy-to-clipboard-button" */).then(c => wrapFunctional(c.default || c))
export const CopyToClipboardInput = () => import('../../components/CopyToClipboardInput.vue' /* webpackChunkName: "components/copy-to-clipboard-input" */).then(c => wrapFunctional(c.default || c))
export const CostOfGoodsSoldCsvImport = () => import('../../components/CostOfGoodsSoldCsvImport.vue' /* webpackChunkName: "components/cost-of-goods-sold-csv-import" */).then(c => wrapFunctional(c.default || c))
export const CurrencyDropdown = () => import('../../components/CurrencyDropdown.vue' /* webpackChunkName: "components/currency-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelector = () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c))
export const CustomerSatisfaction = () => import('../../components/CustomerSatisfaction.vue' /* webpackChunkName: "components/customer-satisfaction" */).then(c => wrapFunctional(c.default || c))
export const DataServiceAccount = () => import('../../components/DataServiceAccount.vue' /* webpackChunkName: "components/data-service-account" */).then(c => wrapFunctional(c.default || c))
export const ExportButton = () => import('../../components/ExportButton.vue' /* webpackChunkName: "components/export-button" */).then(c => wrapFunctional(c.default || c))
export const FilterButton = () => import('../../components/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c))
export const FilterPopupMenu = () => import('../../components/FilterPopupMenu.vue' /* webpackChunkName: "components/filter-popup-menu" */).then(c => wrapFunctional(c.default || c))
export const FilterTreeviewMenu = () => import('../../components/FilterTreeviewMenu.vue' /* webpackChunkName: "components/filter-treeview-menu" */).then(c => wrapFunctional(c.default || c))
export const FormSwitch = () => import('../../components/FormSwitch.vue' /* webpackChunkName: "components/form-switch" */).then(c => wrapFunctional(c.default || c))
export const FormattedCurrency = () => import('../../components/FormattedCurrency.vue' /* webpackChunkName: "components/formatted-currency" */).then(c => wrapFunctional(c.default || c))
export const FormattedNumber = () => import('../../components/FormattedNumber.vue' /* webpackChunkName: "components/formatted-number" */).then(c => wrapFunctional(c.default || c))
export const FormattedPercentage = () => import('../../components/FormattedPercentage.vue' /* webpackChunkName: "components/formatted-percentage" */).then(c => wrapFunctional(c.default || c))
export const FormattedTimestamp = () => import('../../components/FormattedTimestamp.vue' /* webpackChunkName: "components/formatted-timestamp" */).then(c => wrapFunctional(c.default || c))
export const FreePlanOverlay = () => import('../../components/FreePlanOverlay.vue' /* webpackChunkName: "components/free-plan-overlay" */).then(c => wrapFunctional(c.default || c))
export const FundingEstimate = () => import('../../components/FundingEstimate.vue' /* webpackChunkName: "components/funding-estimate" */).then(c => wrapFunctional(c.default || c))
export const IconListItem = () => import('../../components/IconListItem.vue' /* webpackChunkName: "components/icon-list-item" */).then(c => wrapFunctional(c.default || c))
export const InfoTooltip = () => import('../../components/InfoTooltip.vue' /* webpackChunkName: "components/info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const InviteUsersDialog = () => import('../../components/InviteUsersDialog.vue' /* webpackChunkName: "components/invite-users-dialog" */).then(c => wrapFunctional(c.default || c))
export const KPISummary = () => import('../../components/KPISummary.vue' /* webpackChunkName: "components/k-p-i-summary" */).then(c => wrapFunctional(c.default || c))
export const KeywordRanking = () => import('../../components/KeywordRanking.vue' /* webpackChunkName: "components/keyword-ranking" */).then(c => wrapFunctional(c.default || c))
export const KpiCard = () => import('../../components/KpiCard.vue' /* webpackChunkName: "components/kpi-card" */).then(c => wrapFunctional(c.default || c))
export const KpiCardTitle = () => import('../../components/KpiCardTitle.vue' /* webpackChunkName: "components/kpi-card-title" */).then(c => wrapFunctional(c.default || c))
export const ListingElement = () => import('../../components/ListingElement.vue' /* webpackChunkName: "components/listing-element" */).then(c => wrapFunctional(c.default || c))
export const ListingElementBulletPoints = () => import('../../components/ListingElementBulletPoints.vue' /* webpackChunkName: "components/listing-element-bullet-points" */).then(c => wrapFunctional(c.default || c))
export const ListingElementHeader = () => import('../../components/ListingElementHeader.vue' /* webpackChunkName: "components/listing-element-header" */).then(c => wrapFunctional(c.default || c))
export const ListingElementsOverview = () => import('../../components/ListingElementsOverview.vue' /* webpackChunkName: "components/listing-elements-overview" */).then(c => wrapFunctional(c.default || c))
export const ListingElementsPushStep = () => import('../../components/ListingElementsPushStep.vue' /* webpackChunkName: "components/listing-elements-push-step" */).then(c => wrapFunctional(c.default || c))
export const ListingOptimizationParent = () => import('../../components/ListingOptimizationParent.vue' /* webpackChunkName: "components/listing-optimization-parent" */).then(c => wrapFunctional(c.default || c))
export const ListingOptimizationPatched = () => import('../../components/ListingOptimizationPatched.vue' /* webpackChunkName: "components/listing-optimization-patched" */).then(c => wrapFunctional(c.default || c))
export const ListingOptimizationsMonitoring = () => import('../../components/ListingOptimizationsMonitoring.vue' /* webpackChunkName: "components/listing-optimizations-monitoring" */).then(c => wrapFunctional(c.default || c))
export const ListingPanel = () => import('../../components/ListingPanel.vue' /* webpackChunkName: "components/listing-panel" */).then(c => wrapFunctional(c.default || c))
export const ListingPanelParent = () => import('../../components/ListingPanelParent.vue' /* webpackChunkName: "components/listing-panel-parent" */).then(c => wrapFunctional(c.default || c))
export const ListingPushToAmazonDialog = () => import('../../components/ListingPushToAmazonDialog.vue' /* webpackChunkName: "components/listing-push-to-amazon-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketingBanner = () => import('../../components/MarketingBanner.vue' /* webpackChunkName: "components/marketing-banner" */).then(c => wrapFunctional(c.default || c))
export const NavigationBreadcrumbs = () => import('../../components/NavigationBreadcrumbs.vue' /* webpackChunkName: "components/navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const OperationsPnl = () => import('../../components/OperationsPnl.vue' /* webpackChunkName: "components/operations-pnl" */).then(c => wrapFunctional(c.default || c))
export const Paper = () => import('../../components/Paper.vue' /* webpackChunkName: "components/paper" */).then(c => wrapFunctional(c.default || c))
export const PercentageChangeIndicator = () => import('../../components/PercentageChangeIndicator.vue' /* webpackChunkName: "components/percentage-change-indicator" */).then(c => wrapFunctional(c.default || c))
export const Period = () => import('../../components/Period.vue' /* webpackChunkName: "components/period" */).then(c => wrapFunctional(c.default || c))
export const ProductChart = () => import('../../components/ProductChart.vue' /* webpackChunkName: "components/product-chart" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailsTable = () => import('../../components/ProductDetailsTable.vue' /* webpackChunkName: "components/product-details-table" */).then(c => wrapFunctional(c.default || c))
export const ProductPerformance = () => import('../../components/ProductPerformance.vue' /* webpackChunkName: "components/product-performance" */).then(c => wrapFunctional(c.default || c))
export const ProfitabilityVisualization = () => import('../../components/ProfitabilityVisualization.vue' /* webpackChunkName: "components/profitability-visualization" */).then(c => wrapFunctional(c.default || c))
export const SalesMetricsChart = () => import('../../components/SalesMetricsChart.vue' /* webpackChunkName: "components/sales-metrics-chart" */).then(c => wrapFunctional(c.default || c))
export const SalesMetricsPieChart = () => import('../../components/SalesMetricsPieChart.vue' /* webpackChunkName: "components/sales-metrics-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const SceletonList = () => import('../../components/SceletonList.vue' /* webpackChunkName: "components/sceleton-list" */).then(c => wrapFunctional(c.default || c))
export const ScoreBar = () => import('../../components/ScoreBar.vue' /* webpackChunkName: "components/score-bar" */).then(c => wrapFunctional(c.default || c))
export const ScoreBox = () => import('../../components/ScoreBox.vue' /* webpackChunkName: "components/score-box" */).then(c => wrapFunctional(c.default || c))
export const SellerAdvertisingOverview = () => import('../../components/SellerAdvertisingOverview.vue' /* webpackChunkName: "components/seller-advertising-overview" */).then(c => wrapFunctional(c.default || c))
export const SellerBrandsOverviewTable = () => import('../../components/SellerBrandsOverviewTable.vue' /* webpackChunkName: "components/seller-brands-overview-table" */).then(c => wrapFunctional(c.default || c))
export const SellerNavbar = () => import('../../components/SellerNavbar.vue' /* webpackChunkName: "components/seller-navbar" */).then(c => wrapFunctional(c.default || c))
export const SellerOverviewTitle = () => import('../../components/SellerOverviewTitle.vue' /* webpackChunkName: "components/seller-overview-title" */).then(c => wrapFunctional(c.default || c))
export const ShopifyShopConnectDialog = () => import('../../components/ShopifyShopConnectDialog.vue' /* webpackChunkName: "components/shopify-shop-connect-dialog" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTable = () => import('../../components/SkeletonTable.vue' /* webpackChunkName: "components/skeleton-table" */).then(c => wrapFunctional(c.default || c))
export const SkuCogsSubmitStatus = () => import('../../components/SkuCogsSubmitStatus.vue' /* webpackChunkName: "components/sku-cogs-submit-status" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDialog = () => import('../../components/SubscriptionDialog.vue' /* webpackChunkName: "components/subscription-dialog" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const TopListingOptimizationOpportunities = () => import('../../components/TopListingOptimizationOpportunities.vue' /* webpackChunkName: "components/top-listing-optimization-opportunities" */).then(c => wrapFunctional(c.default || c))
export const TopOpportunityAlert = () => import('../../components/TopOpportunityAlert.vue' /* webpackChunkName: "components/top-opportunity-alert" */).then(c => wrapFunctional(c.default || c))
export const TopOpportunityChildAsin = () => import('../../components/TopOpportunityChildAsin.vue' /* webpackChunkName: "components/top-opportunity-child-asin" */).then(c => wrapFunctional(c.default || c))
export const TopProducts = () => import('../../components/TopProducts.vue' /* webpackChunkName: "components/top-products" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const Valuation = () => import('../../components/Valuation.vue' /* webpackChunkName: "components/valuation" */).then(c => wrapFunctional(c.default || c))
export const WeeklyOverview = () => import('../../components/WeeklyOverview.vue' /* webpackChunkName: "components/weekly-overview" */).then(c => wrapFunctional(c.default || c))
export const WeeklyOverviewChart = () => import('../../components/WeeklyOverviewChart.vue' /* webpackChunkName: "components/weekly-overview-chart" */).then(c => wrapFunctional(c.default || c))
export const WeeklyOverviewFilters = () => import('../../components/WeeklyOverviewFilters.vue' /* webpackChunkName: "components/weekly-overview-filters" */).then(c => wrapFunctional(c.default || c))
export const Widget = () => import('../../components/Widget.vue' /* webpackChunkName: "components/widget" */).then(c => wrapFunctional(c.default || c))
export const AdvertisingAsinsTable = () => import('../../components/advertising/AsinsTable.vue' /* webpackChunkName: "components/advertising-asins-table" */).then(c => wrapFunctional(c.default || c))
export const AdvertisingCampaignsTable = () => import('../../components/advertising/CampaignsTable.vue' /* webpackChunkName: "components/advertising-campaigns-table" */).then(c => wrapFunctional(c.default || c))
export const AggregatorDashboardInviteForm = () => import('../../components/aggregator-dashboard/InviteForm.vue' /* webpackChunkName: "components/aggregator-dashboard-invite-form" */).then(c => wrapFunctional(c.default || c))
export const AggregatorDashboardSellersList = () => import('../../components/aggregator-dashboard/SellersList.vue' /* webpackChunkName: "components/aggregator-dashboard-sellers-list" */).then(c => wrapFunctional(c.default || c))
export const SellerActionItem = () => import('../../components/seller/ActionItem.vue' /* webpackChunkName: "components/seller-action-item" */).then(c => wrapFunctional(c.default || c))
export const SellerMonthPickerMenu = () => import('../../components/seller/MonthPickerMenu.vue' /* webpackChunkName: "components/seller-month-picker-menu" */).then(c => wrapFunctional(c.default || c))
export const SellerNavigationTabs = () => import('../../components/seller/NavigationTabs.vue' /* webpackChunkName: "components/seller-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const SellerOnboardingStepper = () => import('../../components/seller/OnboardingStepper.vue' /* webpackChunkName: "components/seller-onboarding-stepper" */).then(c => wrapFunctional(c.default || c))
export const SellerProfitLossTable = () => import('../../components/seller/ProfitLossTable.vue' /* webpackChunkName: "components/seller-profit-loss-table" */).then(c => wrapFunctional(c.default || c))
export const SellerProfitLossTableHeader = () => import('../../components/seller/ProfitLossTableHeader.vue' /* webpackChunkName: "components/seller-profit-loss-table-header" */).then(c => wrapFunctional(c.default || c))
export const SellerNavDropdown = () => import('../../components/seller/SellerNavDropdown.vue' /* webpackChunkName: "components/seller-nav-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SellerNavLink = () => import('../../components/seller/SellerNavLink.vue' /* webpackChunkName: "components/seller-nav-link" */).then(c => wrapFunctional(c.default || c))
export const SellerSummary = () => import('../../components/seller/Summary.vue' /* webpackChunkName: "components/seller-summary" */).then(c => wrapFunctional(c.default || c))
export const StepperHeader = () => import('../../components/stepper/Header.vue' /* webpackChunkName: "components/stepper-header" */).then(c => wrapFunctional(c.default || c))
export const StepperItem = () => import('../../components/stepper/Item.vue' /* webpackChunkName: "components/stepper-item" */).then(c => wrapFunctional(c.default || c))
export const WidgetSubtitle = () => import('../../components/widget/Subtitle.vue' /* webpackChunkName: "components/widget-subtitle" */).then(c => wrapFunctional(c.default || c))
export const WidgetTitle = () => import('../../components/widget/Title.vue' /* webpackChunkName: "components/widget-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

import {
  InferType,
  ref,
} from 'yup';

import Password from './Password';

const ConfirmedPassword = Password.oneOf([
  ref('password'),
], 'Passwords must match');

// eslint-disable-next-line @typescript-eslint/no-redeclare
type ConfirmedPassword = InferType<typeof ConfirmedPassword>

export default ConfirmedPassword;

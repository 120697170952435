
import {
  keyBy,
} from 'lodash';

import beaconToggle from '@/helpers/beaconToggle';

export default {
  name: 'SubscriptionDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    isCreatingPaymentSession: false,
    priceId: '',
    mode: 'month',
    prices: [
    ],
    pricesById: {
    },
    currency: 'usd',
  }),
  computed: {
    email() {
      const email = this.$store.getters['session/user']?.email || null;
      return email;
    },
    fullName() {
      const fullName = this.$store.getters['session/fullName'];
      return fullName;
    },
    priceOptions() {
      return Object.values(this.pricesById)
        .filter((price) => price.interval === this.mode)
        .map((price) => ({
          ...price,
          price: this.formatCurrency(price.currencyOptions[this.currency] / 100, this.currency),
        }));
    },
    selectedPrice() {
      const selectedPrice = this.pricesById[this.priceId];

      return selectedPrice;
    },
    isSubscribed() {
      return this.$store.getters['session/isSubscribed'];
    },
  },
  watch: {
    isOpen(value) {
      if (value && !this.isSubscribed) {
        this.getSubscriptionPrices();
      }
    },
    mode() {
      if (this.selectedPrice) {
        const parallelModePrice = this.priceOptions.find(
          (price) => price.revenueCondition === this.selectedPrice?.revenueCondition,
        );

        this.priceId = parallelModePrice?.id || '';
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changePrice(id) {
      this.priceId = id;
    },

    formatCurrency(value, currency) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
      });
    },

    async getSubscriptionPrices() {
      this.loading = true;
      const {
        prices,
      } = await this.$apiClient.getSubscriptionPrices();

      this.pricesById = keyBy(prices, 'id');
      this.loading = false;
    },
    async createPaymentSession() {
      this.isCreatingPaymentSession = true;
      const {
        url,
      } = await this.$apiClient.createPaymentSession({
        priceId: this.priceId,
      });

      this.isCreatingPaymentSession = false;
      window.location.assign(url);
    },
    openHelpScout() {
      const {
        fullName,
        email,
      } = this;

      beaconToggle(window, {
        email,
        name: fullName,
      });
    },
  },
};

export const ERROR = 'error';
export const WARNING = 'warning';
export const DEFAULT = 'default';
export const SUCCESS = 'success';
export const NOTIFICATION_TYPES = {
  ERROR,
  WARNING,
  DEFAULT,
  SUCCESS,
};

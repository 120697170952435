/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionTree, GetterTree, MutationTree,
} from 'vuex';

import {
  GetListingOptimizationsPerSeller,
  OptimizationPatched,
} from '@/api/clients/ProductHistorianApi.types';
import ApiClient from '@/plugins/ApiClient';

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $apiClient: ApiClient,
  }
}

const {
  GET_LISTING_OPTIMIZATION_SUCCESS,
  UPDATE_LISTING_OPTIMIZATION_MONITORING_PARAMS,
  UPDATE_IS_LOADING_LISTING_OPTIMIZATIONS,
  RESET_LISTING_OPTIMIZATIONS_MONITORING_PARAMS,
} = {
  UPDATE_IS_LOADING_LISTING_OPTIMIZATIONS: 'UPDATE_IS_LOADING_LISTING_OPTIMIZATIONS',
  GET_LISTING_OPTIMIZATION_SUCCESS: 'GET_LISTING_OPTIMIZATION_SUCCESS',
  UPDATE_LISTING_OPTIMIZATION_MONITORING_PARAMS: 'UPDATE_LISTING_OPTIMIZATION_MONITORING_PARAMS',
  RESET_LISTING_OPTIMIZATIONS_MONITORING_PARAMS: 'RESET_LISTING_OPTIMIZATIONS_MONITORING_PARAMS',
};

export type RootState = {
  isLoadingListingOptimizations: boolean
  listingOptimizations: OptimizationPatched[]
  listingOptimizationsMonitoringFilters: {
    search: string
    unconfirmedOnly: boolean
  }
  listingOptimizationsMonitoringPagination: {
    page: number
    perPage: number
    totalPages: number,
    totalItems: number,
  }
}

export const state = ():RootState => ({
  isLoadingListingOptimizations: false,
  listingOptimizations: [
  ],
  listingOptimizationsMonitoringFilters: {
    search: '',
    unconfirmedOnly: false,
  },
  listingOptimizationsMonitoringPagination: {
    page: 0,
    perPage: 5,
    totalPages: 0,
    totalItems: 0,
  },
});

export const getters: GetterTree<RootState, RootState> = {
  isLoadingListingOptimizations: ({
    isLoadingListingOptimizations,
  }) => isLoadingListingOptimizations,
  listingOptimizations: ({
    listingOptimizations,
  }) => listingOptimizations,
  listingOptimizationsMonitoringFilters: ({
    listingOptimizationsMonitoringFilters,
  }) => listingOptimizationsMonitoringFilters,
  listingOptimizationsMonitoringPagination: ({
    listingOptimizationsMonitoringPagination,
  }) => listingOptimizationsMonitoringPagination,
};

export const mutations: MutationTree<RootState> = {
  [GET_LISTING_OPTIMIZATION_SUCCESS]: (stt, {
    pagination,
    patches,
  }: GetListingOptimizationsPerSeller.Response) => {
    stt.listingOptimizations = patches;
    stt.listingOptimizationsMonitoringPagination
      .page = pagination.current_page;
    stt.listingOptimizationsMonitoringPagination
      .totalPages = pagination.total_pages;
    stt.listingOptimizationsMonitoringPagination
      .totalItems = pagination.total_count;
  },
  [UPDATE_LISTING_OPTIMIZATION_MONITORING_PARAMS]: (stt, {
    page,
    itemsPerPage,
    search,
    unconfirmedOnly,
  }: {
    page: number,
    itemsPerPage: number,
    search: string,
    unconfirmedOnly: boolean,
  }) => {
    stt.listingOptimizationsMonitoringPagination.page = page;
    stt.listingOptimizationsMonitoringPagination.perPage = itemsPerPage;
    stt.listingOptimizationsMonitoringFilters.search = search;
    stt.listingOptimizationsMonitoringFilters.unconfirmedOnly = unconfirmedOnly;
  },
  [UPDATE_IS_LOADING_LISTING_OPTIMIZATIONS]: (stt, newValue: boolean) => {
    stt.isLoadingListingOptimizations = newValue;
  },
  [RESET_LISTING_OPTIMIZATIONS_MONITORING_PARAMS]: (stt) => {
    stt.listingOptimizationsMonitoringPagination = {
      page: 0,
      perPage: 5,
      totalPages: 0,
      totalItems: 0,
    };
    stt.listingOptimizationsMonitoringFilters = {
      search: '',
      unconfirmedOnly: false,
    };
  },
};

export const actions:ActionTree<RootState, RootState> = {
  async getListingOptimizations({
    commit,
    state: optimizationsState,
  }, {
    sellerId,
  }: {
    sellerId: number,
  }) {
    const {
      listingOptimizationsMonitoringPagination: {
        page,
        perPage,
      },
      listingOptimizationsMonitoringFilters: {
        search,
        unconfirmedOnly,
      },
    } = optimizationsState;

    const response = await this.$apiClient
      .getListingOptimizationsPerSeller(sellerId, {
        page,
        perPage,
        search,
        unconfirmedOnly,
      });
    commit(GET_LISTING_OPTIMIZATION_SUCCESS, response);
  },
  updateListingOptimizationsMonitoringParams({
    commit,
  }, payload: {
    page: number,
    itemsPerPage: number,
    search: string,
    unconfirmedOnly: boolean,
  }) { commit(UPDATE_LISTING_OPTIMIZATION_MONITORING_PARAMS, payload); },
  updateIsLoadingListingOptimizations({
    commit,
  }, newValue) { commit(UPDATE_IS_LOADING_LISTING_OPTIMIZATIONS, newValue); },
  resetListingOptimizationsMonitoringParams({
    commit,
  }) { commit(RESET_LISTING_OPTIMIZATIONS_MONITORING_PARAMS); },
};

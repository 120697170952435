const middleware = {}

middleware['admin'] = require('../middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['emailVerification'] = require('../middleware/emailVerification.ts')
middleware['emailVerification'] = middleware['emailVerification'].default || middleware['emailVerification']

middleware['flash'] = require('../middleware/flash.ts')
middleware['flash'] = middleware['flash'].default || middleware['flash']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['serviceProviderAuthErrors'] = require('../middleware/serviceProviderAuthErrors.ts')
middleware['serviceProviderAuthErrors'] = middleware['serviceProviderAuthErrors'].default || middleware['serviceProviderAuthErrors']

middleware['shopifyInstallAuth'] = require('../middleware/shopifyInstallAuth.ts')
middleware['shopifyInstallAuth'] = middleware['shopifyInstallAuth'].default || middleware['shopifyInstallAuth']

export default middleware

import {
  InferType,
  string,
} from 'yup';

const Password = string().min(8).max(64);

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Password = InferType<typeof Password>

export default Password;

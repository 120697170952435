
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      title:
        'An error occurred',
    };
  },
};

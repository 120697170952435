import jwt from 'jsonwebtoken';
import {
  InferType, string,
} from 'yup';

import {
  stateSecret,
} from '@/environment';
import isPresent from '@/helpers/isPresent';

const Jwt = string().test('isJwt', 'invalid JWT', (value) => {
  if (!isPresent(value)) {
    return false;
  }

  try {
    jwt.verify(value as string, stateSecret);
    return true;
  } catch (error) {
    return false;
  }
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Jwt = InferType<typeof Jwt>

export default Jwt;

enum ProfitLossFinancialItems {
  fullPriceFulfilledProductSales = 'Full Price Fulfilled Product Sales',
  unitsSold = 'Units Sold',
  productDiscounts = 'Product Discounts',
  fulfilledProductSales = 'Fulfilled Product Sales',
  fulfilledShippingRevenue = 'Fulfilled Shipping Revenue',
  otherIncome = 'Other Income',
  totalShippedSales = 'Total Shipped Sales',
  productReturns = 'Product Returns',
  netSales = 'Net Sales',
  landedCostOfGoodsSold = 'Landed Cost of Goods Sold',
  fbaInventoryAdjustment = 'FBA Inventory Adjustment',
  productCost = 'Product Costs',
  contributionMargin1 = 'Contribution Margin 1',
  fbaDisposalFees = 'FBA Disposal Fees',
  fbaMfnOutboundFees = 'FBA MFN Outbound Fees',
  fbaInboudFees = 'FBA Inbound Fees',
  fbaInboundFreightFees = 'FBA Inbound Freight Fees',
  fbaInboundFreightTaxAndDutyFees = 'FBA Inbound Freight Tax and Duty Fees',
  fbaOutboundFees = 'FBA Outbound Fees',
  fbaRefunds = 'FBA Refunds',
  fbaReimbursements = 'FBA Reimbursements',
  fbaStorageFees = 'FBA Storage Fees',
  totalFbaFees = 'Total FBA Fees',
  sellingFees = 'Selling Fees',
  contributionMargin2 = 'Contribution Margin 2',
  amazonAdExpenses = 'Amazon Ad Expenses',
  otherAdExpenses = 'Other Ad Expenses',
  totalAdExpenses = 'Total Ad Expenses',
  contributionMargin3 = 'Contribution Margin 3',
  otherExpenses = 'Other Expenses',
  otherPlatformFees = 'Other Platform Fees',
  totalExpenses = 'Total Expenses',
  amazonEbitda = 'Amazon EBITDA',
  otherPlatformEbitda = 'Other Platform EBITDA',
  totalEbitda = 'Total EBITDA',
  salesTax = 'Sales Tax',
  amazonSalesTaxRemitted = 'Amazon Sales Tax Remitted',
  amazonReserves = 'Amazon Reserves',
  amazonLending = 'Amazon Lending',
}

export default ProfitLossFinancialItems;

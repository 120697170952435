
import SellerNavbar from '@/components/SellerNavbar.vue';
import beaconToggle from '@/helpers/beaconToggle';

export default {
  name: 'Visualisations',
  components: {
    SellerNavbar,
  },
  computed: {
    isListingPanelOpen() {
      return this.$store.getters['listingOptimizationChild/isListingPanelOpen'];
    },
    email() {
      const email = this.$store.getters['session/user']?.email || null;
      return email;
    },
    fullName() {
      const fullName = this.$store.getters['session/fullName'];
      return fullName;
    },
    showAutopilotPrompt() {
      const acceptedAutopilotTnC = this.$store.getters['session/acceptedAutopilotTnC'];
      const isAdminSession = this.$store.getters['session/isAdminSession'];
      return !acceptedAutopilotTnC && !isAdminSession;
    },
  },
  methods: {
    async acceptAutopilotTermsAndConditions() {
      await this.$store.dispatch('session/acceptAutopilotTermsAndConditions');
    },
    openHelpScout() {
      const {
        fullName,
        email,
      } = this;

      beaconToggle(window, {
        email,
        name: fullName,
      });
    },
  },
};

import {
  keyBy,
} from 'lodash';

import Region from '@/enums/Region';

export type MarketplaceMetadata = {
  marketplaceId: string,
  marketplaceName: string,
  domainName: string,
  countryCode:string,
  defaultCurrencyCode: string,
  defaultLocale: string,
  region: Region | '',
  listingOptimizationEnabled: boolean,
}

export const AMAZON_MARKETPLACES: MarketplaceMetadata[] = [
  {
    marketplaceId: 'A2Q3Y263D00KWC',
    marketplaceName: 'Amazon.com.br',
    domainName: 'www.amazon.com.br',
    countryCode: 'BR',
    defaultCurrencyCode: 'BRL',
    defaultLocale: 'pt_BR',
    region: Region.na,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A2EUQ1WTGCTBG2',
    marketplaceName: 'Amazon.ca',
    domainName: 'www.amazon.ca',
    countryCode: 'CA',
    defaultCurrencyCode: 'CAD',
    defaultLocale: 'en_CA',
    region: Region.na,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'A1AM78C64UM0Y8',
    marketplaceName: 'Amazon.com.mx',
    domainName: 'www.amazon.com.mx',
    countryCode: 'MX',
    defaultCurrencyCode: 'MXN',
    defaultLocale: 'es_MX',
    region: Region.na,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'ATVPDKIKX0DER',
    marketplaceName: 'Amazon.com',
    domainName: 'www.amazon.com',
    countryCode: 'US',
    defaultCurrencyCode: 'USD',
    defaultLocale: 'en_US',
    region: Region.na,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'A21TJRUUN4KGV',
    marketplaceName: 'Amazon.in',
    domainName: 'www.amazon.in',
    countryCode: 'IN',
    defaultCurrencyCode: 'INR',
    defaultLocale: 'en_IN',
    region: Region.eu,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'A2NODRKZP88ZB9',
    marketplaceName: 'Amazon.se',
    domainName: 'www.amazon.se',
    countryCode: 'SE',
    defaultCurrencyCode: 'SEK',
    defaultLocale: 'sv_SE',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A13V1IB3VIYZZH',
    marketplaceName: 'Amazon.fr',
    domainName: 'www.amazon.fr',
    countryCode: 'FR',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'fr_FR',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1PA6795UKMFR9',
    marketplaceName: 'Amazon.de',
    domainName: 'www.amazon.de',
    countryCode: 'DE',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'de_DE',
    region: Region.eu,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'AMEN7PMS3EDWL',
    marketplaceName: 'Amazon.com.be',
    domainName: 'www.amazon.com.be',
    countryCode: 'BE',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'fr_BE',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1RKKUPIHCS9HS',
    marketplaceName: 'Amazon.es',
    domainName: 'www.amazon.es',
    countryCode: 'ES',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'es_ES',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'APJ6JRA9NG5V4',
    marketplaceName: 'Amazon.it',
    domainName: 'www.amazon.it',
    countryCode: 'IT',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'it_IT',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A33AVAJ2PDY3EV',
    marketplaceName: 'Amazon.com.tr',
    domainName: 'www.amazon.com.tr',
    countryCode: 'TR',
    defaultCurrencyCode: 'TRY',
    defaultLocale: 'tr_TR',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1805IZSGTT6HS',
    marketplaceName: 'Amazon.nl',
    domainName: 'www.amazon.nl',
    countryCode: 'NL',
    defaultCurrencyCode: 'EUR',
    defaultLocale: 'nl_NL',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1C3SOZRARQ6R3',
    marketplaceName: 'Amazon.pl',
    domainName: 'www.amazon.pl',
    countryCode: 'PL',
    defaultCurrencyCode: 'PLN',
    defaultLocale: 'pl_PL',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1F83G8C2ARO7P',
    marketplaceName: 'Amazon.co.uk',
    domainName: 'www.amazon.co.uk',
    countryCode: 'GB',
    defaultCurrencyCode: 'GBP',
    defaultLocale: 'en_GB',
    region: Region.eu,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'ARBP9OOSHTCHU',
    marketplaceName: 'Amazon.eg',
    domainName: 'www.amazon.eg',
    countryCode: 'EG',
    defaultCurrencyCode: 'EGP',
    defaultLocale: 'ar_EG',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A17E79C6D8DWNP',
    marketplaceName: 'Amazon.sa',
    domainName: 'www.amazon.sa',
    countryCode: 'SA',
    defaultCurrencyCode: 'SAR',
    defaultLocale: 'ar_SA',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A2VIGQ35RCS4UG',
    marketplaceName: 'Amazon.ae',
    domainName: 'www.amazon.ae',
    countryCode: 'AE',
    defaultCurrencyCode: 'AED',
    defaultLocale: 'ar_AE',
    region: Region.eu,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A1VC38T7YXB528',
    marketplaceName: 'Amazon.co.jp',
    domainName: 'www.amazon.jp',
    countryCode: 'JP',
    defaultCurrencyCode: 'JPY',
    defaultLocale: 'ja_JP',
    region: Region.fe,
    listingOptimizationEnabled: false,
  },
  {
    marketplaceId: 'A19VAU5U5O7RUS',
    marketplaceName: 'Amazon.sg',
    domainName: 'www.amazon.sg',
    countryCode: 'SG',
    defaultCurrencyCode: 'SGD',
    defaultLocale: 'en_SG',
    region: Region.fe,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'A39IBJ37TRP1C6',
    marketplaceName: 'Amazon.com.au',
    domainName: 'www.amazon.com.au',
    countryCode: 'AU',
    defaultCurrencyCode: 'AUD',
    defaultLocale: 'en_AU',
    region: Region.fe,
    listingOptimizationEnabled: true,
  },
  {
    marketplaceId: 'A2ZV50J4W1RKNI',
    marketplaceName: 'Non-Amazon',
    domainName: '',
    countryCode: '',
    defaultCurrencyCode: '',
    defaultLocale: '',
    region: '',
    listingOptimizationEnabled: false,
  },
];

export const AMAZON_MARKETPLACES_BY_ID = keyBy(AMAZON_MARKETPLACES, 'marketplaceId');

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';

const flashMiddleware: Middleware = async ({
  store,
  $cookies,
}) => {
  const flash = $cookies.get('flash', {
    parseJSON: true,
  });

  if (flash) {
    $cookies.remove('flash');
    await store.dispatch('alerts/setNotification', {
      type: flash.type,
      html: true,
      message: flash.message,
    });
  }
};

export default flashMiddleware;

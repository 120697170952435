
import {
  NOTIFICATION_TYPES,
} from '@/constants';

export default {
  name: 'Notification',
  data() {
    return {
      iconColor: {
        error: '#ff4a4a',
        warning: '#ff8a00',
        default: '#4c537a',
        success: '#111633',
      },
      backgroundColor: {
        success: 'success',
        error: 'error',
        warning: 'warning',
        default: 'secondary',
      },
    };
  },
  computed: {
    notificationTimeout() {
      return this.notification.type === NOTIFICATION_TYPES.ERROR ? '-1' : '5000';
    },
    contentClass() {
      const notificationTypeClass = {
        [NOTIFICATION_TYPES.ERROR]: 'snack-notification-error',
        [NOTIFICATION_TYPES.WARNING]: 'snack-notification-warning',
        [NOTIFICATION_TYPES.DEFAULT]: 'snack-notification-default',
        [NOTIFICATION_TYPES.SUCCESS]: 'snack-notification-success',
      };
      return notificationTypeClass[this.notification.type];
    },
    showNotification() {
      return this.$store.getters['alerts/hasNotification'];
    },
    notification() {
      return this.$store.getters['alerts/notification'];
    },
    html() {
      return !!this.notification.html;
    },
    message() {
      return this.notification.message;
    },
  },
  methods: {
    closeNotification() {
      if (this.showNotification) {
        this.$store.dispatch('alerts/deleteNotification');
      }
    },
  },
};


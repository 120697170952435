// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Context,
  Middleware,
} from '@nuxt/types';

import createSentryContextForSession from '@/helpers/client/createSentryContextForSession';

const getReturnToQueryFromRouteParams = (route:Context['route']) => {
  const {
    name,
    params,
    path,
    query,
    hash,
  } = route;
  const location: {
    params?: Record<string, string>,
    path?:string,
    name?: string,
    hash: string,
    query: Record<string, string | (string | null)[]>
  } = {
    query,
    hash,
  };
  if (name) {
    location.name = name;
    location.params = params;
  } else {
    location.path = path;
  }

  return encodeURIComponent(JSON.stringify(location));
};

const authMiddleware:Middleware = async ({
  $api,
  redirect,
  route,
  store,
}) => {
  const {
    meta,
  } = route;

  const routeMeta = meta ? meta[0] : {
  };

  const {
    admin,
    stopRedirectToLoginIfNotAuthenticated,
    skipAuth,
  } = routeMeta || {
  };

  const verifySession = async () => {
    const {
      data: {
        user,
        organization,
        isAdminSession,
        userRole,
      },
    } = await $api.post('/sessions/verification');

    await store.dispatch('sellers/get');
    store.commit('session/login', {
      organization,
      user,
      isAdminSession,
      userRole,
    });

    createSentryContextForSession({
      user,
    });
  };

  const isVerificationNeeded = !admin && !store.getters['session/isLoggedIn'] && !skipAuth;

  if (isVerificationNeeded) {
    try {
      await verifySession();
    } catch (err) {
      // Some pages do not need redirection to login when
      // the user is not authenticated (e.g. sign-up, login)
      if (stopRedirectToLoginIfNotAuthenticated) {
        return;
      }

      redirect({
        name: 'login',
        query: {
          returnTo: getReturnToQueryFromRouteParams(route),
        },
      });
    }
  }
};

export default authMiddleware;

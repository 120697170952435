
import keyBy from 'lodash/keyBy';

const routeNameToBreadcrumbName = {
  // dashboard: 'Dashboard',
  'dashboard-sellers-sellerId-operations-assistant': 'Operations Assistant',
  'dashboard-sellers-sellerId-inventory': 'Inventory',
  'dashboard-sellers-sellerId-reports': 'Download Reports',
  'dashboard-sellers-sellerId-product-overview': 'Products',
  'dashboard-sellers-sellerId-advertising': 'Advertising',
  'dashboard-sellers-sellerId-account-health': 'Account Health',
  'dashboard-sellers-sellerId': 'Brand Valuation',
  'dashboard-sellers-sellerId-brand-valuation': 'Brand Valuation',
  'dashboard-sellers-sellerId-settings': 'Settings',
  'admin-aggregators': 'Aggregators',
  'admin-sellers': 'Sellers',
  'admin-sellers-sellerId': 'seller',
  settings: 'Settings',
};
export default {
  name: 'NavigationBreadcrumbs',
  computed: {
    items() {
      const sellers = this.$store.getters['sellers/sellers'];
      const sellersBySlug = keyBy(sellers, 'slug');

      const items = [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
      ];

      if (this.$route.params.sellerId) {
        const seller = sellersBySlug[this.$route.params.sellerId];
        if (seller) {
          const sellerLink = {
            text: seller.name || seller.slug,
            disabled: false,
            exact: true,
            to: `/dashboard/sellers/${this.$route.params.sellerId}/settings/`,
          };

          items.push(sellerLink);
        }
      }

      items.push({
        text: routeNameToBreadcrumbName[this.$route.name],
        disabled: false,
        exact: true,
      });

      return items;
    },
  },
  methods: {
    redirect(router) {
      this.$store.dispatch('listingOptimizationChild/updateListingPanel', {
        asin: null,
        marketplaceId: null,
      });
      this.$store.dispatch('listingOptimizationChild/closeListingPanel');
      this.$router.push(router);
    },
    isDisabled(routeName) {
      return this.$route.name === routeName;
    },
  },
};

/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionTree, GetterTree, MutationTree,
} from 'vuex';

import {
  AMAZON_MARKETPLACES_BY_ID,
} from '@/constants/amazonMarketplaces';
import ApiClient from '@/plugins/ApiClient';

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $apiClient: ApiClient,
  }
}

export type RootState = {
  areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus: boolean
  listingPanelParentData: {
    asin: string | null,
    marketplaceId: string | null
    listingOptimizationEnabled: boolean
  }
  isSubscriptionDialogOpen: boolean
  isListingPanelParentOpen: boolean
  isBrandConfigurationsDialogOpen: boolean
  forceOptimization: boolean
  isShopifyCustomAccountDialogOpen: boolean,
  isAmazonSpapiAuthDialogOpen: boolean,
  amazonAuthRegion: string,
  isAmazonSpapiAlertShown: boolean
  isRainforestDataAvailable: boolean
}

const ACTIONS = {
  UPDATE_AMAZON_SP_ACCOUNTS_STATUS: 'UPDATE_AMAZON_SP_ACCOUNTS_STATUS',
  UPDATE_IS_AMAZON_SPAPI_ALERT_SHOWN: 'UPDATE_IS_AMAZON_SPAPI_ALERT_SHOWN',
  UPDATE_LISTING_PANEL_PARENT: 'UPDATE_LISTING_PANEL_PARENT',
  OPEN_LISTING_PANEL_PARENT: 'OPEN_LISTING_PANEL_PARENT',
  CLOSE_LISTING_PANEL_PARENT: 'CLOSE_LISTING_PANEL_PARENT',
  OPEN_SHOPIFY_CUSTOM_ACCOUNT_DIALOG: 'OPEN_SHOPIFY_CUSTOM_ACCOUNT_DIALOG',
  CLOSE_SHOPIFY_CUSTOM_ACCOUNT_DIALOG: 'CLOSE_SHOPIFY_CUSTOM_ACCOUNT_DIALOG',
  OPEN_AMAZON_SPAPI_AUTH_DIALOG: 'OPEN_AMAZON_SPAPI_AUTH_DIALOG',
  CLOSE_AMAZON_SPAPI_AUTH_DIALOG: 'CLOSE_AMAZON_SPAPI_AUTH_DIALOG',
  OPEN_BRAND_CONFIGURATION_DIALOG: 'OPEN_BRAND_CONFIGURATION_DIALOG',
  CLOSE_BRAND_CONFIGURATION_DIALOG: 'CLOSE_BRAND_CONFIGURATION_DIALOG',
};

export const state = ():RootState => ({
  areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus: false,
  isSubscriptionDialogOpen: false,
  listingPanelParentData: {
    asin: null,
    marketplaceId: null,
    listingOptimizationEnabled: false,
  },
  isListingPanelParentOpen: false,
  isBrandConfigurationsDialogOpen: false,
  forceOptimization: false,
  isShopifyCustomAccountDialogOpen: false,
  isAmazonSpapiAuthDialogOpen: false,
  amazonAuthRegion: '',
  isAmazonSpapiAlertShown: false,
  isRainforestDataAvailable: false,
});

export const getters: GetterTree<RootState, RootState> = {
  isRainforestDataAvailable: ({
    isRainforestDataAvailable,
  }) => isRainforestDataAvailable,
  areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus: ({
    areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus,
  }) => areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus,
  isBrandConfigurationsDialogOpen: ({
    isBrandConfigurationsDialogOpen,
  }) => isBrandConfigurationsDialogOpen,
  isListingPanelParentOpen: ({
    isListingPanelParentOpen,
  }) => isListingPanelParentOpen,
  listingPanelParentData: ({
    listingPanelParentData,
  }) => {
    const marketplaceMetadata = listingPanelParentData.marketplaceId
      ? AMAZON_MARKETPLACES_BY_ID[listingPanelParentData.marketplaceId]
      : null;
    return {
      ...listingPanelParentData,
      marketplaceName: marketplaceMetadata?.marketplaceName,
      listingOptimizationEnabled: marketplaceMetadata?.listingOptimizationEnabled || false,
    };
  },
  forceOptimization: ({
    forceOptimization,
  }) => forceOptimization,
  isSubscriptionDialogOpen: ({
    isSubscriptionDialogOpen,
  }) => isSubscriptionDialogOpen,
  isShopifyCustomAccountDialogOpen: ({
    isShopifyCustomAccountDialogOpen,
  }) => isShopifyCustomAccountDialogOpen,
  amazonAuthRegion: ({
    amazonAuthRegion,
  }) => amazonAuthRegion,
  isAmazonSpapiAuthDialogOpen: ({
    isAmazonSpapiAuthDialogOpen,
  }) => isAmazonSpapiAuthDialogOpen,
  isAmazonSpapiAlertShown: ({
    isAmazonSpapiAlertShown,
  }) => isAmazonSpapiAlertShown,
};

export const mutations: MutationTree<RootState> = {
  [ACTIONS.CLOSE_LISTING_PANEL_PARENT]: (stt) => {
    stt.isListingPanelParentOpen = false;
  },
  OPEN_SUBSCRIPTION_DIALOG: (stt) => {
    stt.isSubscriptionDialogOpen = true;
  },
  CLOSE_SUBSCRIPTION_DIALOG: (stt) => {
    stt.isSubscriptionDialogOpen = false;
  },
  [ACTIONS.OPEN_LISTING_PANEL_PARENT]: (stt, payload) => {
    stt.isListingPanelParentOpen = true;
    stt.forceOptimization = payload.forceOptimization;
  },
  [ACTIONS.UPDATE_LISTING_PANEL_PARENT]: (stt, payload: {
    asin: string | null,
    marketplaceId: string | null,
    listingOptimizationEnabled: boolean
  }) => {
    stt.listingPanelParentData = payload;
  },
  [ACTIONS.UPDATE_AMAZON_SP_ACCOUNTS_STATUS]: (stt, payload: {
    alertStatus: boolean,
  }) => {
    stt.areAnySellerAmazonSpAccountsAtRiskOrCriticalStatus = payload.alertStatus;
  },
  [ACTIONS.OPEN_BRAND_CONFIGURATION_DIALOG]: (stt) => {
    stt.isBrandConfigurationsDialogOpen = true;
  },
  [ACTIONS.CLOSE_BRAND_CONFIGURATION_DIALOG]: (stt) => {
    stt.isBrandConfigurationsDialogOpen = false;
  },
  [ACTIONS.OPEN_SHOPIFY_CUSTOM_ACCOUNT_DIALOG]: (stt) => {
    stt.isShopifyCustomAccountDialogOpen = true;
  },
  [ACTIONS.CLOSE_SHOPIFY_CUSTOM_ACCOUNT_DIALOG]: (stt) => {
    stt.isShopifyCustomAccountDialogOpen = false;
  },
  [ACTIONS.OPEN_AMAZON_SPAPI_AUTH_DIALOG]: (stt, payload: {
    region: string
  }) => {
    stt.isAmazonSpapiAuthDialogOpen = true;
    stt.amazonAuthRegion = payload.region;
  },
  [ACTIONS.CLOSE_AMAZON_SPAPI_AUTH_DIALOG]: (stt) => {
    stt.isAmazonSpapiAuthDialogOpen = false;
    stt.amazonAuthRegion = '';
  },
  [ACTIONS.UPDATE_IS_AMAZON_SPAPI_ALERT_SHOWN]: (stt) => {
    stt.isAmazonSpapiAlertShown = !stt.isAmazonSpapiAlertShown;
  },
};

export const actions:ActionTree<RootState, RootState> = {
  updateAmazonSpAccountsStatus({
    commit,
  }, payload: {
    alertStatus: boolean
  }) {
    commit(ACTIONS.UPDATE_AMAZON_SP_ACCOUNTS_STATUS, {
      alertStatus: payload.alertStatus,
    });
  },
  updateListingPanelParent({
    commit,
  }, payload: {
    asin: string | null
    marketplaceId: string | null
  }) {
    commit(ACTIONS.UPDATE_LISTING_PANEL_PARENT, payload);
  },
  openListingPanelParent({
    commit,
  }, payload: {
    forceOptimization: boolean,
  } = {
    forceOptimization: false,
  }) {
    commit(ACTIONS.OPEN_LISTING_PANEL_PARENT, payload);
  },
  closeListingPanelParent({
    commit,
  }) {
    commit(ACTIONS.CLOSE_LISTING_PANEL_PARENT);
  },
  openSubscriptionDialog({
    commit,
  }) {
    commit('OPEN_SUBSCRIPTION_DIALOG');
  },
  closeSubscriptionDialog({
    commit,
  }) {
    commit('CLOSE_SUBSCRIPTION_DIALOG');
  },
  openBrandConfigurationDialog({
    commit,
  }) {
    commit(ACTIONS.OPEN_BRAND_CONFIGURATION_DIALOG);
  },
  closeBrandConfigurationDialog({
    commit,
  }) {
    commit(ACTIONS.CLOSE_BRAND_CONFIGURATION_DIALOG);
  },
  openShopifyCustomAccountDialog({
    commit,
  }) {
    commit(ACTIONS.OPEN_SHOPIFY_CUSTOM_ACCOUNT_DIALOG);
  },
  closeShopifyCustomAccountDialog({
    commit,
  }) {
    commit(ACTIONS.CLOSE_SHOPIFY_CUSTOM_ACCOUNT_DIALOG);
  },
  openAmazonSpapiAuthDialog({
    commit,
  }, payload: {
    region: string
  }) {
    commit(ACTIONS.OPEN_AMAZON_SPAPI_AUTH_DIALOG, {
      region: payload.region,
    });
  },
  closeAmazonSpapiAuthDialog({
    commit,
  }) {
    commit(ACTIONS.CLOSE_AMAZON_SPAPI_AUTH_DIALOG);
  },
  updateIsAmazonSpapiAlertShown({
    commit,
  }) {
    commit(ACTIONS.UPDATE_IS_AMAZON_SPAPI_ALERT_SHOWN);
  },
};

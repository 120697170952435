import {
  InferType,
  string,
} from 'yup';

import RegionEnum from '@/enums/Region';

const Region = string().required().oneOf(Object.keys(RegionEnum), 'Unsupported region');

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Region = InferType<typeof Region> & `${RegionEnum}`

export default Region;

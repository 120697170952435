// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Middleware,
} from '@nuxt/types';

const adminMiddleware: Middleware = async ({
  error,
  route,
  $adminApiClient,
}) => {
  if (route.meta && route.meta.some((m:Record<string, any>) => m.admin)) {
    try {
      await $adminApiClient.getProfile();
    } catch (err) {
      error({
        statusCode: 403,
        message: 'Forbidden',
      });
    }
  }
};

export default adminMiddleware;

import {
  InferType, object, string,
} from 'yup';

import ConfirmedPassword from './ConfirmedPassword';
import Email from './Email';
import Password from './Password';

const CreateUser = object({
  email: Email.required(),
  password: Password.required(),
  confirmedPassword: ConfirmedPassword.required(),
  sellerName: string(),
}).required();

// eslint-disable-next-line @typescript-eslint/no-redeclare
type CreateUser = InferType<typeof CreateUser>

export default CreateUser;

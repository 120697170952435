
import {
  defineComponent,
} from 'vue';

import {
  NOTIFICATION_TYPES,
} from '@/constants';
import {
  Email,
} from '@/validations';

export default defineComponent({
  name: 'InviteUsersDialog',
  Email,
  data() {
    return {
      dialog: false,
      alert: {
        isShown: false,
        color: null,
        message: '',
        icon: null,
      },
      emailTextFields: [
        {
          id: 'email-1',
          value: '',
        },
      ],
      errorMessagesByField: {
        email: [
        ],
      },
    };
  },
  errorMessages: {
    email: 'Malformed e-mail address',
  },
  computed: {
    userId() {
      const user = this.$store.getters['session/user'];

      return user.id;
    },
    organizationName() {
      const org = this.$store.getters['session/organization'];

      return org.name || org.slug;
    },
    isOperationsUser() {
      return this.$store.getters['session/isOperationsUser'];
    },
  },
  methods: {
    async sendUserInvites() {
      if (!this.$refs.sendUserInvites.validate()) {
        return;
      }

      const {
        userId,
        emailTextFields,
      } = this;

      if (emailTextFields.length === 1 && emailTextFields[0].value === '') {
        this.alert = {
          isShown: true,
          color: 'mustard-yellow',
          icon: '$exclamation-triangle',
          message: 'Write at least one email address',
        };
        return;
      }

      const emailAddresses = emailTextFields.map((emailTextField) => (emailTextField.value));

      try {
        const {
          inviteSent,
        } = await this.$apiClient.inviteUsersToOrganization(userId, {
          emailAddresses,
        });

        this.showResponse(inviteSent);
      } catch (err) {
        this.alert = {
          isShown: true,
          color: NOTIFICATION_TYPES.ERROR,
          icon: '$hexagon-exclamation',
          message: err || 'Something went wrong',
        };
      }
    },
    showResponse(inviteSent) {
      let emailAlreadyRegisteredAlertText = '';
      const emailAlreadyRegisteredList = [
      ];

      if (inviteSent.length <= 0) {
        this.alert = {
          isShown: true,
          color: 'mustard-yellow',
          icon: '$exclamation-triangle',
          message: 'No email sent',
        };
        return;
      }

      const emailAlreadyRegistered = inviteSent.filter((
        email,
      ) => (email.emailAlreadyRegistered));

      if (emailAlreadyRegistered.length === inviteSent.length) {
        this.alert = {
          isShown: true,
          color: 'mustard-yellow',
          icon: '$exclamation-triangle',
          message: 'This email already has an Autopilot account - contact support if you need help assigning it to your organization',
        };

        return;
      }

      if (emailAlreadyRegistered.length > 0) {
        emailAlreadyRegistered.forEach((email) => {
          emailAlreadyRegisteredList.push(email.emailAddress);
        });

        emailAlreadyRegisteredAlertText = `These emails already have an Autopilot account: ${emailAlreadyRegisteredList.join(', ')}`;

        this.alert = {
          isShown: true,
          color: 'mustard-yellow',
          icon: '$exclamation-triangle',
          message: emailAlreadyRegisteredAlertText,
        };
      }

      this.$store.dispatch('alerts/setNotification', {
        message: 'You have successfully sent an email invitation. You will receive a confirmation email when the user signs up',
        type: NOTIFICATION_TYPES.SUCCESS,
      });
      this.closeDialog();
    },
    clearError(field, hasError) {
      if (hasError) {
        return;
      }
      this.$set(this.errorMessagesByField, field, [
      ]);
    },
    addEmailAddres() {
      const newEmailTextField = {
        id: `email-${this.emailTextFields.length + 1}`,
        value: '',
      };

      this.emailTextFields.push(newEmailTextField);
    },
    closeDialog() {
      this.dialog = false;
      this.emailTextFields = [
        {
          id: 'email-1',
          value: '',
        },
      ];
      this.alert = {
        isShown: false,
        color: null,
        icon: null,
        message: '',
      };
    },
  },
});

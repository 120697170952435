import {
  WeeklyPerformanceMetricKeys,
  WeeklyPerformanceMetricType,
} from '@/api/clients/sellerDataApi.types';

export type WeeklyOverviewMetricsSetup = {
  key: WeeklyPerformanceMetricKeys,
  value: WeeklyPerformanceMetricType,
  text: string,
  unitType: (payload?: {
    targetCurrency: string,
  }) => string,
  disabled: boolean,
}[]

const WEEKLY_OVERVIEW_METRICS_NAMES = {
  UNITS_SOLD: 'Units Sold',
  AVERAGE_SELLING_PRICE: 'Average Selling Price',
  REVIEWS: 'Reviews',
  BSR: 'BSR',
  RETURNS: 'Returns',
  LISTING_PERFORMANCE: 'Listing Performance',
  PAID_TO_ORGANIC_RATION: 'Paid to Organic Ration',
  AD_SPEND: 'Advertising Spend',
  DISCOUNTS: 'Discounts',
  NET_MARGIN: 'Net Margin',
};

const WEEKLY_OVERVIEW_METRICS_SETUP = [
  {
    key: 'unitsSold',
    value: 'units-sold',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.UNITS_SOLD,
    unitType: () => 'Number of Units Sold',
    disabled: false,
  },
  {
    key: 'averageSellingPrice',
    value: 'average-selling-price',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.AVERAGE_SELLING_PRICE,
    unitType: (payload: {
      targetCurrency: string
    }) => `${WEEKLY_OVERVIEW_METRICS_NAMES.AVERAGE_SELLING_PRICE} ${payload.targetCurrency}`,
    disabled: false,
  },
  {
    key: 'adSpend',
    value: 'ad-spend',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.AD_SPEND,
    unitType: (payload: {
      targetCurrency: string
    }) => `${WEEKLY_OVERVIEW_METRICS_NAMES.AD_SPEND} ${payload.targetCurrency}`,
    disabled: true,
  },
  {
    key: 'reviews',
    value: 'reviews',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.REVIEWS,
    unitType: () => 'Number of Reviews',
    disabled: true,
  },
  {
    key: 'bsr',
    value: 'bsr',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.BSR,
    unitType: (payload: {
      targetCurrency: string
    }) => `${WEEKLY_OVERVIEW_METRICS_NAMES.BSR} ${payload.targetCurrency}`,
    disabled: true,
  },
  {
    key: 'returns',
    value: 'returns',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.RETURNS,
    unitType: () => 'Number of Returns',
    disabled: true,
  },
  {
    key: 'listingPerformance',
    value: 'listing-performance',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.BSR,
    unitType: (payload: {
      targetCurrency: string
    }) => `${WEEKLY_OVERVIEW_METRICS_NAMES.BSR} ${payload.targetCurrency}`,
    disabled: true,
  },
  {
    key: 'paidToOrganicRation',
    value: 'paid-to-organic-ration',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.PAID_TO_ORGANIC_RATION,
    unitType: (payload: {
      targetCurrency: string
    }) => `${WEEKLY_OVERVIEW_METRICS_NAMES.PAID_TO_ORGANIC_RATION} ${payload.targetCurrency}`,
    disabled: true,
  },
  {
    key: 'netMargin',
    value: 'net-margin',
    text: WEEKLY_OVERVIEW_METRICS_NAMES.NET_MARGIN,
    unitType: () => '% of Net Margin',
    disabled: true,
  },
] as WeeklyOverviewMetricsSetup;

export default {
  WEEKLY_OVERVIEW_METRICS_SETUP,
  WEEKLY_OVERVIEW_METRICS_NAMES,
};

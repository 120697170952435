import ProfitLossFinancialItems from '@/enums/ProfitLossFinancialItems';

export const TRAILING_12_MONTHS_TEXT = 'Trailing 12 Months';
export const TRAILING_12_MONTHS_VALUE = 'trailing12Months';
export const OF_NET_SALES = 'ofNetSales';
export const PNL_VALUE_NAME = 'name';

export const YEAR_DATA_PNL = 'Totals';

export const PROFIT_LOSS_FINANCIAL_ITEMS = [
  ProfitLossFinancialItems.fullPriceFulfilledProductSales,
  ProfitLossFinancialItems.unitsSold,
  ProfitLossFinancialItems.productDiscounts,
  ProfitLossFinancialItems.fulfilledProductSales,
  ProfitLossFinancialItems.fulfilledShippingRevenue,
  ProfitLossFinancialItems.otherIncome,
  ProfitLossFinancialItems.totalShippedSales,
  ProfitLossFinancialItems.productReturns,
  ProfitLossFinancialItems.netSales,
  ProfitLossFinancialItems.landedCostOfGoodsSold,
  ProfitLossFinancialItems.fbaInventoryAdjustment,
  ProfitLossFinancialItems.productCost,
  ProfitLossFinancialItems.contributionMargin1,
  ProfitLossFinancialItems.fbaDisposalFees,
  ProfitLossFinancialItems.fbaMfnOutboundFees,
  ProfitLossFinancialItems.fbaInboudFees,
  ProfitLossFinancialItems.fbaInboundFreightFees,
  ProfitLossFinancialItems.fbaInboundFreightTaxAndDutyFees,
  ProfitLossFinancialItems.fbaOutboundFees,
  ProfitLossFinancialItems.fbaRefunds,
  ProfitLossFinancialItems.fbaReimbursements,
  ProfitLossFinancialItems.fbaStorageFees,
  ProfitLossFinancialItems.totalFbaFees,
  ProfitLossFinancialItems.sellingFees,
  ProfitLossFinancialItems.contributionMargin2,
  ProfitLossFinancialItems.amazonAdExpenses,
  ProfitLossFinancialItems.otherAdExpenses,
  ProfitLossFinancialItems.totalAdExpenses,
  ProfitLossFinancialItems.contributionMargin3,
  ProfitLossFinancialItems.otherExpenses,
  ProfitLossFinancialItems.otherPlatformFees,
  ProfitLossFinancialItems.totalExpenses,
  ProfitLossFinancialItems.amazonEbitda,
  ProfitLossFinancialItems.otherPlatformEbitda,
  ProfitLossFinancialItems.totalEbitda,
  ProfitLossFinancialItems.salesTax,
  ProfitLossFinancialItems.amazonSalesTaxRemitted,
  ProfitLossFinancialItems.amazonReserves,
  ProfitLossFinancialItems.amazonLending,
];

export const KEYS_TO_EXTEND_WITH_PERCENTAGE_OF_NET_SALES = [
  ProfitLossFinancialItems.productCost,
  ProfitLossFinancialItems.totalFbaFees,
  ProfitLossFinancialItems.sellingFees,
  ProfitLossFinancialItems.totalAdExpenses,
  ProfitLossFinancialItems.otherExpenses,
  ProfitLossFinancialItems.otherPlatformFees,
  ProfitLossFinancialItems.contributionMargin1,
  ProfitLossFinancialItems.contributionMargin2,
  ProfitLossFinancialItems.contributionMargin3,
  ProfitLossFinancialItems.totalEbitda,
  ProfitLossFinancialItems.totalExpenses,
];

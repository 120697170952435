import {
  InferType,
  object,
  string,
} from 'yup';

import {
  CURRENCIES,
} from '@/constants';

const UpdateUser = object({
  defaultCurrencyCode: string().oneOf([
    ...CURRENCIES,
  ]),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type UpdateUser = InferType<typeof UpdateUser>

export default UpdateUser;
